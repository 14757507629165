import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { AvatarImage, type AvatarImageProps } from 'radix-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarImage',
  props: {
    src: {},
    asChild: { type: Boolean },
    as: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AvatarImage), _mergeProps(props, { class: "h-full w-full object-cover" }), null, 16))
}
}

})