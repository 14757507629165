<template>
  <div class="min-h-screen text-gray-100 p-8">
    <!-- <h1 class="text-4xl font-bold text-center mb-8">Ranking de Membros</h1> -->

    <TopThree />
    
    <div class="mt-8 max-w-7xl mx-auto bg-card rounded-lg shadow-xl overflow-hidden">
      <div class="p-6">
        <div class="flex flex-wrap items-center justify-between gap-4 mb-6">
          <Input 
            placeholder="Pesquisar membro..." 
            class="max-w-xs"
            v-model="searchQuery"
          />
          <!-- <Select v-model="selectedCategory">
            <SelectTrigger class="w-[180px]">
              <SelectValue placeholder="Categoria" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="overall">Geral</SelectItem>
              <SelectItem value="monthly">Mensal</SelectItem>
              <SelectItem value="weekly">Semanal</SelectItem>
            </SelectContent>
          </Select> -->
        </div>
        
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead class="w-[100px]">Posição</TableHead>
              <TableHead>Membro</TableHead>
              <TableHead>Patente</TableHead>
              <TableHead>Pontos</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow 
              v-for="(member, index) in filteredMembers" 
              :key="member.id"
              :class="{'bg-yellow-900/20': index === 0, 'bg-gray-400/10': index === 1, 'bg-orange-900/20': index === 2}"
              class="transition-all hover:bg-gray-700/50"
            >
              <TableCell class="font-medium">
                <div class="flex items-center gap-2">
                  <div 
                    :class="[
                      'w-8 h-8 rounded-full flex items-center justify-center text-xs font-bold',
                      index === 0 ? 'bg-yellow-500 text-yellow-900' :
                      index === 1 ? 'bg-gray-300 text-gray-800' :
                      index === 2 ? 'bg-orange-500 text-orange-900' :
                      'bg-gray-700'
                    ]"
                  >
                    {{ index + 1 }}
                  </div>
                  <TrendingUpIcon v-if="member.trend === 'up'" class="text-green-500" />
                  <TrendingDownIcon v-else-if="member.trend === 'down'" class="text-red-500" />
                </div>
              </TableCell>
              <TableCell>
                <div class="flex items-center gap-3">
                  <Avatar>
                    <AvatarImage :src="member.avatar" :alt="member.name" />
                    <AvatarFallback>{{ member.initials }}</AvatarFallback>
                  </Avatar>
                  <div>
                    <div class="font-semibold">{{ member.name }}</div>
                    <div class="text-sm text-gray-400">{{ member.title }}</div>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <div class="flex items-center gap-2">
                  <ShieldIcon :class="[
                    'w-5 h-5',
                    member.level < 10 ? 'text-gray-500' :
                    member.level < 20 ? 'text-blue-500' :
                    member.level < 30 ? 'text-purple-500' :
                    'text-yellow-500'
                  ]" />
                  {{ member.level }}
                </div>
              </TableCell>
              <TableCell>
                <div class="font-mono">{{ member.points.toLocaleString() }}</div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>

    <!-- <Card class="max-w-7xl mx-auto mt-8 bg-card">
      <CardHeader>
        <CardTitle>Estatísticas do Ranking</CardTitle>
      </CardHeader>
      <CardContent>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div class="space-y-2">
            <div class="text-2xl font-bold">{{ totalMembers }}</div>
            <div class="text-sm text-gray-400">Total de Membros</div>
          </div>
          <div class="space-y-2">
            <div class="text-2xl font-bold">{{ averagePoints.toLocaleString() }}</div>
            <div class="text-sm text-gray-400">Média de Pontos</div>
          </div>
          <div class="space-y-2">
            <div class="text-2xl font-bold">{{ topLevelMember.name }}</div>
            <div class="text-sm text-gray-400">Membro de Maior Nível</div>
          </div>
        </div>
      </CardContent>
    </Card> -->
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
// import { Progress } from '@/components/ui/progress'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { TrendingUpIcon, TrendingDownIcon, ShieldIcon } from 'lucide-vue-next'
import TopThree from './components/TopThree.vue'
import { useRanking } from '@/composables/useRanking'

const { initializeRankings } = useRanking()
// Simulated data
const members = ref([
  { id: 1, name: 'Alice Johnson', initials: 'AJ', avatar: '/placeholder.svg?height=40&width=40', title: 'Desenvolvedor Sênior', level: 32, points: 15780, progress: 78, trend: 'up' },
  { id: 2, name: 'Bob Smith', initials: 'BS', avatar: '/placeholder.svg?height=40&width=40', title: 'Designer UX', level: 28, points: 14200, progress: 65, trend: 'down' },
  { id: 3, name: 'Carol Williams', initials: 'CW', avatar: '/placeholder.svg?height=40&width=40', title: 'Gerente de Projeto', level: 25, points: 12500, progress: 92, trend: 'up' },
  // Add more members here...
])

const searchQuery = ref('')
const selectedCategory = ref('overall')

const filteredMembers = computed(() => {
  return members.value
    .filter(member => member.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
    .sort((a, b) => b.points - a.points)
})

const totalMembers = computed(() => members.value.length)
const averagePoints = computed(() => {
  const total = members.value.reduce((sum, member) => sum + member.points, 0)
  return Math.round(total / members.value.length)
})
const topLevelMember = computed(() => {
  return members.value.reduce((top, member) => member.level > top.level ? member : top, members.value[0])
})

onMounted(() => {
  initializeRankings()
})
</script>

<style scoped>
/* Add any additional styles here */
</style>