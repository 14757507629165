<template>
  <div class="min-h-screen bg-background flex flex-col">
    <header class="border-b">
      <div class="container mx-auto px-4 py-6">
        <h1 class="text-2xl font-bold text-foreground">Termos de Uso</h1>
      </div>
    </header>

    <main class="flex-grow container mx-auto px-4 py-8">
      <Card class="max-w-3xl mx-auto">
        <CardHeader>
          <CardTitle class="text-xl font-semibold">Por favor, leia atentamente nossos termos</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea class="h-[60vh] pr-4">
            <div class="space-y-4 text-muted-foreground" v-html="tenant.termsOfUse"></div>
          </ScrollArea>
        </CardContent>
      </Card>
    </main>

    <footer class="border-t bg-muted/50">
      <div class="container mx-auto px-4 py-6 text-center">
        <p class="text-sm text-muted-foreground">© 2024 Sua Empresa. Todos os direitos reservados.</p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useTenant } from '@/composables/useTenant';

const { tenant } = useTenant()
</script>