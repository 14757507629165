import { ref } from "vue"
import { useAxios } from "./useAxios"
import { AxiosResponse } from "axios"
import { Profile } from "@/interfaces/profiles/Profile"
import { AclEnum } from '@/enums/acl/AclEnum'

const isAuthLoading = ref(false)
const isChangingPassword = ref(false)
const axios = useAxios()

export interface LoginPayload {
  email: string
  password: string
}

export function useAuth() {
  const userAuthenticated = JSON.parse(localStorage.getItem('authUser') ?? '{}')

  function login(payload: LoginPayload, onSuccess: (res: AxiosResponse) => void, onError: (error: any) => void) {
    isAuthLoading.value = true

    axios.post('/sessions', payload)
      .then((res) => {
        localStorage.setItem('authToken', res.data.token)
        localStorage.setItem('authUserId', res.data.userId)
        localStorage.setItem('authUser', JSON.stringify(res.data))

        onSuccess(res)
      })
      .catch((error) => {
        onError(error)
      })
      .finally(() => isAuthLoading.value = false)
  }

  function logout(callback: Function) {
    localStorage.removeItem('authToken')
    callback()
  }

  function sendResetPasswordLink(email: string, onSuccess: (res: AxiosResponse) => void, onError: (error: any) => void) {
    isAuthLoading.value = true

    axios.put('/accounts/password/recovery', { email })
      .then((res) => {
        onSuccess(res)
      })
      .catch((error) => {
        onError(error)
      })
      .finally(() => isAuthLoading.value = false)
  }

  function resetPassword(payload: { token: string, password: string }, onSuccess: (res: AxiosResponse) => void, onError: (error: any) => void) {
    isAuthLoading.value = true

    axios.patch('/accounts/password/recovery', payload)
      .then((res) => {
        onSuccess(res)
      })
      .catch((error) => {
        onError(error)
      })
      .finally(() => isAuthLoading.value = false)
  }

  async function getProfile(): Promise<Profile> {
    const res = await axios.get(`/profile/${userAuthenticated.userId}`)

    return res.data
  }

  async function changePassword(payload: { passwordOld: string, password: string, passwordConfirm: string }, onSuccess: Function, onError: Function): Promise<boolean> {
    isChangingPassword.value = true

    try {
      await axios.put('/accounts', payload)
      onSuccess()
    } catch (e) {
      onError()
    }

    isChangingPassword.value = false

    return true
  }

  function hasRoles(roles: string[]) {
    return roles.some((roleName: string) => {
      return userAuthenticated?.roles?.some((role: any) => {
        return role === roleName
      })
    })
  }

  return {
    userAuthenticated,
    login,
    logout,
    isAuthLoading,
    sendResetPasswordLink,
    resetPassword,
    getProfile,
    changePassword,
    isChangingPassword,
    hasRoles
  }
}
