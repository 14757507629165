<template>
  <Card class="w-full">
    <CardContent class="p-0">
      <!-- Cover Photo -->
      <div class="h-48 bg-gray-400 relative">
        <input v-if="userAuthenticated.userId === user.userId" class="hidden" accept="image/png, image/jpg, image/jpeg, image/gif, image/avif" type="file" @change="onAvatarChange" id="avatar">
        <input v-if="userAuthenticated.userId === user.userId" class="hidden" accept="image/png, image/jpg, image/jpeg, image/gif, image/avif" type="file" @change="onBannerChange" id="banner">
        <label
          for="banner"
          class="absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer"
          v-if="userAuthenticated.userId === user.userId"
        >
          <ImageUp
            v-if="
              !banner?.bucketLocation &&
              !bannerPreview
            "
            class="w-4 h-4"
          />
          <Pencil v-else class="w-4 h-4" />
        </label>
        <img
          v-if="banner || bannerPreview"
          :src="bannerPreview ? bannerPreview : banner!.bucketLocation"
          alt="Cover Photo"
          class="w-full h-full object-cover"
        />
        <label for="avatar">
          <Avatar
            class="absolute bottom-0 left-4 transform translate-y-1/2 border-4 border-white"
            size="lg"
          >
            <AvatarImage
              v-if="avatar?.bucketLocation || avatarPreview"
              :src="avatarPreview ? avatarPreview : avatar!.bucketLocation"
            />
            <AvatarFallback>{{
              user.name
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase())
                .join("")
            }}</AvatarFallback>
          </Avatar>
        </label>
      </div>

      <!-- Profile Info -->
      <div class="pt-16 px-4">
        <div class="flex justify-between items-start mb-4">
          <div>
            <h2 class="text-2xl font-bold">{{ user.name }}</h2>
            <!-- <p class="text-gray-500">{{ user.description }}</p> -->
          </div>
          <div class="grid gap-2" v-if="userAuthenticated.userId === user.userId">
            <Button @click="openEditModal">Editar perfil</Button>
            <Button v-if="!!profilePayload.avatar || !!profilePayload.banner" @click="updateProfile" class="bg-emerald-500 text-white hover:bg-emerald-600">
              <Loader2 v-if="isUpdatingProfile" class="w-4 h-4 mr-2 animate-spin" />
              Salvar alterações
            </Button>
          </div>
        </div>

        <p class="mb-4">{{ user.description }}</p>

        <!-- Stats -->
        <div class="flex justify-between mb-6">
          <div class="text-center">
            <p class="font-semibold">{{ topics?.pagination.total }}</p>
            <p class="text-sm text-gray-500">Publicações</p>
          </div>
          <!-- <div class="text-center">
            <p class="font-semibold">{{ user.followers }}</p>
            <p class="text-sm text-gray-500">Seguidores</p>
          </div> -->
          <!-- <div class="text-center">
              <p class="font-semibold">{{ user.following }}</p>
              <p class="text-sm text-gray-500">Following</p>
            </div> -->
        </div>

        <!-- Achievements section -->
        <div class="mb-6">
          <h3 class="font-semibold mb-2">Conquistas</h3>
          <div class="flex flex-wrap gap-2">
            <TooltipProvider>
              <Tooltip
                v-for="achievement in achievements"
                :key="achievement.name"
              >
                <TooltipTrigger>
                  <Badge variant="secondary" class="p-2">
                    <component :is="achievement.icon" class="h-5 w-5" />
                  </Badge>
                </TooltipTrigger>
                <TooltipContent>
                  <p class="font-semibold">{{ achievement.name }}</p>
                  <p>{{ achievement.description }}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>

      <!-- Tabs -->
      <Tabs default-value="posts" class="w-full">
        <TabsList class="w-full">
          <TabsTrigger value="posts" class="flex-1">Publicações</TabsTrigger>
          <TabsTrigger value="trades" class="flex-1">Trades Recentes</TabsTrigger>
          <!-- <TabsTrigger value="media" class="flex-1">Media</TabsTrigger> -->
        </TabsList>
        <TabsContent value="posts">
          <Card v-if="userAuthenticated.userId === user.userId" class="">
            <CardHeader>
              <CardTitle class="text-[1.1rem]">Criar Publicação</CardTitle>
            </CardHeader>
            <CardContent class="grid gap-6">
              <Textarea v-model="topicPayload.content" placeholder="No que você está pensando?" />
            </CardContent>
            <CardFooter class="flex justify-between">
              <!-- <Button variant="ghost" size="sm">
                <ImageIcon class="h-5 w-5 mr-2" />
                Foto
              </Button>
              <Button variant="ghost" size="sm">
                <VideoIcon class="h-5 w-5 mr-2" />
                Vídeo
              </Button> -->
              <Button :disabled="!topicPayload.content || isCreatingTopic" @click="createTopic">
                <Loader2 v-if="isCreatingTopic" class="w-4 h-4 mr-2 animate-spin" />
                Publicar
              </Button>
            </CardFooter>
          </Card>
          <div v-if="!!topics" class="p-4 space-y-4">
             <ProfileTopicComponent v-for="topic in topics.data" :profile="user" :key="topic.id" :topic="topic" @delete="topics.data = topics.data.filter((t) => t.id !== topic.id)" />
              <PaginationApi :data="topics.pagination" :paginate="getTopics" />
          </div>
        </TabsContent>
        <TabsContent value="trades">
          <div class="p-4 space-y-4">
            <Card v-for="trade in user.recentTrades" :key="trade.id">
              <CardHeader>
                <CardTitle>{{ trade.pair }}</CardTitle>
                <CardDescription>{{ trade.date }}</CardDescription>
              </CardHeader>
              <CardContent>
                <div class="flex justify-between">
                  <div>
                    <p class="text-sm text-gray-500">Type</p>
                    <p
                      :class="{
                        'text-green-600': trade.type === 'Buy',
                        'text-red-600': trade.type === 'Sell',
                      }"
                    >
                      {{ trade.type }}
                    </p>
                  </div>
                  <div>
                    <p class="text-sm text-gray-500">Profit/Loss</p>
                    <p
                      :class="{
                        'text-green-600': trade.profitLoss > 0,
                        'text-red-600': trade.profitLoss < 0,
                      }"
                    >
                      {{ trade.profitLoss > 0 ? "+" : ""
                      }}{{ trade.profitLoss }}%
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </TabsContent>
        <TabsContent value="media">
          <div class="p-4 grid grid-cols-3 gap-2">
            <img
              v-for="(media, index) in user.recentMedia"
              :key="index"
              :src="media"
              alt="Media"
              class="w-full h-32 object-cover rounded"
            />
          </div>
        </TabsContent>
      </Tabs>
    </CardContent>

    <!-- Edit Profile Modal -->
    <Dialog v-if="userAuthenticated.userId === user.userId" v-model:open="isEditModalOpen">
      <DialogContent class="w-full max-w-[800px] grid-rows-[auto_minmax(0,1fr)_auto]">
        <DialogHeader>
          <DialogTitle>Editar perfil</DialogTitle>
          <DialogDescription>
            Atualize as informações do seu perfil, dados bancários, senha, e
            preferências de notificação.
          </DialogDescription>
        </DialogHeader>
        <Tabs default-value="profile" class="w-full">
          <TabsList class="grid w-full grid-cols-3">
            <TabsTrigger value="profile">Perfil</TabsTrigger>
            <TabsTrigger value="bank">dados bancários</TabsTrigger>
            <TabsTrigger value="password">Senha</TabsTrigger>
            <!-- <TabsTrigger value="notifications">Notificações</TabsTrigger> -->
          </TabsList>
          <TabsContent value="profile">
            <form @submit.prevent="updateProfile">
              <div class="space-y-4">
                <div class="space-y-2">
                  <Label for="name">Nome</Label>
                  <Input id="name" v-model="profilePayload.name" required />
                </div>
                <div class="space-y-2">
                  <Label for="phoneNumber">Número de telefone</Label>
                  <Input v-maska="'(##) # ####-####'" type="tel" id="phoneNumber" v-model="profilePayload.phoneNumber" required />
                </div>
                <div class="space-y-2">
                  <Label for="description">Descrição</Label>
                  <Textarea id="description" v-model="profilePayload.description" />
                </div>
                <Button @click="updateProfile" type="submit" 
                :disabled="!profilePayload.name ||
                !profilePayload.description ||
                (!!profilePayload.phoneNumber && profilePayload.phoneNumber?.length < 16)">
                  <Loader2 v-if="isUpdatingProfile" class="w-4 h-4 mr-2 animate-spin" />
                  Salvar
                </Button>
              </div>
            </form>
          </TabsContent>
          <TabsContent value="bank">
            <form @submit.prevent="updateBankDetails">
              <div class="space-y-4">
                <div class="grid grid-cols-2 gap-4">
                  <div class="space-y-2">
                    <Label for="bank">Bank</Label>
                    <Input id="bank" v-model="editForm.bank" required />
                  </div>
                  <div class="space-y-2">
                    <Label for="agency">Agency</Label>
                    <Input id="agency" v-model="editForm.agency" required />
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-4">
                  <div class="space-y-2">
                    <Label for="account">Account</Label>
                    <Input id="account" v-model="editForm.account" required />
                  </div>
                  <div class="space-y-2">
                    <Label for="document">Document Number</Label>
                    <Input id="document" v-model="editForm.document" required />
                  </div>
                </div>
                <Button type="submit">Update Bank Details</Button>
              </div>
            </form>
          </TabsContent>
          <TabsContent value="password">
            <form @submit.prevent>
              <div class="space-y-4">
                <div class="space-y-2">
                  <Label for="current-password">Senha atual</Label>
                  <Input
                    id="current-password"
                    type="password"
                    v-model="changePasswordPayload.passwordOld"
                    required
                  />
                </div>
                <div class="space-y-2">
                  <div>
                    <Label for="new-password">Nova senha</Label>
                    <p class="text-xs text-zinc-500">Deve conter no mínimo 8 caracteres</p>
                  </div>
                  <Input
                    id="new-password"
                    type="password"
                    v-model="changePasswordPayload.password"
                    required
                  />
                </div>
                <div class="space-y-2">
                  <Label for="confirm-password">Confirmar nova senha</Label>
                  <Input
                    id="confirm-password"
                    type="password"
                    v-model="changePasswordPayload.passwordConfirm"
                    required
                  />
                </div>
                <Button :disabled="
                isChangingPassword || 
                !changePasswordPayload.passwordOld || 
                !changePasswordPayload.password || 
                !changePasswordPayload.passwordConfirm || 
                changePasswordPayload.password !== changePasswordPayload.passwordConfirm ||
                changePasswordPayload.password.length < 8 ||
                changePasswordPayload.passwordConfirm.length < 8"
                  @click="changePassword(
                    changePasswordPayload, 
                    () => {
                      toast({
                        title: 'Senha alterada com sucesso',
                        class: 'bg-green-500 text-white'
                      })
                      changePasswordPayload = {
                        passwordOld: '',
                        password: '',
                        passwordConfirm: '',
                      }
                    }, 
                    () => toast({
                      title: 'Senha atual incorreta',
                      description: 'Tente novamente',
                      variant: 'destructive'
                    })
                  )" 
                  type="submit"
                >
                  <Loader2 v-if="isChangingPassword" class="w-4 h-4 mr-2 animate-spin" />
                  Salvar
                </Button>
              </div>
            </form>
          </TabsContent>
          <!-- <TabsContent value="notifications">
            <form @submit.prevent="updateNotifications">
              <div class="space-y-4">
                <div class="flex items-center space-x-2">
                  <Checkbox
                    id="email-notifications"
                    v-model="editForm.notifications.email"
                  />
                  <Label for="email-notifications">Email Notifications</Label>
                </div>
                <div class="flex items-center space-x-2">
                  <Checkbox
                    id="push-notifications"
                    v-model="editForm.notifications.push"
                  />
                  <Label for="push-notifications">Push Notifications</Label>
                </div>
                <div class="flex items-center space-x-2">
                  <Checkbox
                    id="sms-notifications"
                    v-model="editForm.notifications.sms"
                  />
                  <Label for="sms-notifications">SMS Notifications</Label>
                </div>
                <Button type="submit">Update Notifications</Button>
              </div>
            </form>
          </TabsContent> -->
        </Tabs>
      </DialogContent>
    </Dialog>
  </Card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  TrendingUpIcon,
  AwardIcon,
  BarChart4Icon,
  StarIcon,
  ZapIcon,
  Pencil,
  ImageUp,
  Loader2,
} from "lucide-vue-next";
import { useToast } from "@/components/ui/toast";
import { Profile } from "@/interfaces/profiles/Profile";
import { ProfileMediaTypeEnum } from "@/enums/profiles/ProfileMediaTypeEnum";
import { useAxios } from "@/composables/useAxios";
import { ProfileTopic } from "@/interfaces/profiles/profileTopics/ProfileTopic";
import { Pagination } from "@/interfaces/Pagination";
import ProfileTopicComponent from "./ProfileTopicComponent.vue";
import PaginationApi from "@/components/ui/pagination/PaginationApi.vue";
import { vMaska } from "maska/vue"
import { useAuth } from "@/composables/useAuth";

const { userAuthenticated, changePassword, isChangingPassword } = useAuth()
const emit = defineEmits(['update'])
const props = defineProps<{ user: Profile }>();
const { toast } = useToast();
const axios = useAxios()
const avatar = computed(() =>
  props.user.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const banner = computed(() =>
  props.user.media.find((m) => m.type === ProfileMediaTypeEnum.BANNER)
);
const avatarPreview = ref<string>()
const bannerPreview = ref<string>()
const isEditModalOpen = ref(false);
const isUpdatingProfile = ref(false);
const editForm = ref({
  bank: "",
  agency: "",
  account: "",
  document: "",
  notifications: {
    email: false,
    push: false,
    sms: false,
  },
});
const changePasswordPayload = ref({
  password: '',
  passwordConfirm: '',
  passwordOld: '',
})
const profilePayload = ref<{
  avatar?: File
  banner?: File
  name?: string
  description?: string,
  phoneNumber?: string
}>({
  name: props.user.name,
  description: props.user.description,
  phoneNumber: props.user.phoneNumber,
})
const topics = ref<Pagination<ProfileTopic>>()
const topicPayload = ref<{ content: string }>({ content: '' })
const isCreatingTopic = ref(false)

// const user = ref({
//     name: 'Jane Doe',
//     avatar: 'https://example.com/avatar.jpg',
//     coverPhoto: 'https://example.com/cover.jpg',
//     description: 'Professional trader and social media enthusiast',
//     posts: 245,
//     followers: '10.5K',
//     // following: 1024,
//     achievements: ['Top Trader', '1000 Trades', '5 Star Rating'],
//     tradingStats: {
//         winRate: 68,
//         totalTrades: 1532,
//         avgProfit: 2.3,
//         avgLoss: 1.1
//     },
//     recentPosts: [
//         { id: 1, content: 'Just closed another successful trade! #trading #success', date: '2h ago' },
//         { id: 2, content: 'Check out my latest market analysis video', date: '1d ago' },
//     ],
//     recentTrades: [
//         { id: 1, pair: 'BTC/USD', type: 'Buy', profitLoss: 2.5, date: '3h ago' },
//         { id: 2, pair: 'ETH/USD', type: 'Sell', profitLoss: -1.2, date: '1d ago' },
//         { id: 3, pair: 'XRP/USD', type: 'Buy', profitLoss: 3.7, date: '2d ago' },
//     ],
//     recentMedia: [
//         'https://example.com/media1.jpg',
//         'https://example.com/media2.jpg',
//         'https://example.com/media3.jpg',
//         'https://example.com/media4.jpg',

//         'https://example.com/media5.jpg',
//         'https://example.com/media6.jpg',
//     ]
// })

const achievements = [
  {
    name: "Top Trader",
    description: "Ranked in the top 1% of traders on the platform",
    icon: TrendingUpIcon,
  },
  {
    name: "1000 Trades",
    description: "Successfully completed 1000 trades",
    icon: BarChart4Icon,
  },
  {
    name: "5 Star Rating",
    description: "Maintained a 5-star rating for over 6 months",
    icon: StarIcon,
  },
  {
    name: "Winning Streak",
    description: "Achieved a winning streak of 10 profitable trades in a row",
    icon: ZapIcon,
  },
  {
    name: "Master Analyst",
    description: "Provided top-rated market analysis for 3 consecutive months",
    icon: AwardIcon,
  },
];

const openEditModal = () => {
  editForm.value = {
    bank: "",
    agency: "",
    account: "",
    document: "",
    notifications: {
      email: false,
      push: false,
      sms: false,
    },
  };
  isEditModalOpen.value = true;
};

const updateBankDetails = () => {
  // Here you would typically send the bank details to your backend
  toast({
    title: "Success",
    description: "Bank details updated successfully",
  });
  isEditModalOpen.value = false;
};

const updateNotifications = () => {
  // Here you would typically send the notification preferences to your backend
  toast({
    title: "Success",
    description: "Notification preferences updated successfully",
  });
  isEditModalOpen.value = false;
};

function onAvatarChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  profilePayload.value.avatar = file

  avatarPreview.value = URL.createObjectURL(file)
}

function onBannerChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  profilePayload.value.banner = file

  bannerPreview.value = URL.createObjectURL(file)
}

function updateProfile() {
  isUpdatingProfile.value = true

  const formData = new FormData()

  for (const key in profilePayload.value) {
    if (profilePayload.value[key as keyof typeof profilePayload.value]) {
      formData.append(key, profilePayload.value[key as keyof typeof profilePayload.value] as any)
    }
  }

  axios.patch(`/profile`, formData)
    .then(() => {
      toast({
        title: 'Perfil atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })
      emit('update')
      isEditModalOpen.value = false;
    })
    .finally(() => isUpdatingProfile.value = false)
}

function createTopic() {
  isCreatingTopic.value = true

  axios.post(`/profile/${props.user.id}/topics`, topicPayload.value)
    .then((res) => {
      getTopics()
      topicPayload.value = { content: '' }
    })
    .finally(() => isCreatingTopic.value = false)
}

function getTopics(page = 1) {
  axios.get(`/profile/${props.user.id}/topics`, { params: { page } })
    .then((res) => {
      topics.value = res.data
    })
}

onMounted(() => {
  getTopics()
})
</script>
