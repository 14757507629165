import { ref } from "vue";
import { useAxios } from "./useAxios";
import { Pagination } from "@/interfaces/Pagination";
import { TournamentRanking } from "@/interfaces/tournaments/TournamentRanking";

const axios = useAxios()
export const rankings = ref<Pagination<TournamentRanking>>()

export function useRanking() {
  async function initializeRankings(tournamentId: number) {
    const response = await axios.get(`/tournaments/${tournamentId}/rankings`)

    rankings.value = response.data
  }
  
  return {
    rankings,
    initializeRankings,
  }
}