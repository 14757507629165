import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex min-h-screen w-full flex-col bg-background" }
const _hoisted_2 = { class: "fixed top-10 left-8 mt-7 h-full flex-col pt-4 hidden md:flex w-30 text-center z-10" }
const _hoisted_3 = { class: "grid gap-6 p-2 pt-0 rounded-lg bg-background" }
const _hoisted_4 = { class: "bg-background sticky top-0 flex h-16 items-center gap-4 border-b mb-3 px-4 md:px-7 z-50" }
const _hoisted_5 = { class: "fixed ml-7 hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6 left-5" }
const _hoisted_6 = {
  href: "/#",
  class: "flex items-center gap-2 text-lg font-semibold md:text-base"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "grid gap-4 text-lg font-medium" }
const _hoisted_9 = {
  href: "#",
  class: "flex items-center gap-2 text-lg font-semibold px-3"
}
const _hoisted_10 = { class: "flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4" }
const _hoisted_11 = {
  key: 0,
  class: "absolute -top-1 -right-1 h-5 w-5 rounded-full bg-red-500 text-xs text-white flex items-center justify-center"
}
const _hoisted_12 = { class: "md:ml-[100px] flex justify-center px-7" }

import {
  Package2,
  Users,
  CircleUser, 
  Menu, 
  LogOut,
  HelpCircle,
  Book,
  LibraryBig,
  Trophy,
  UserCog,
  MessageSquare,
  Waypoints,
  Handshake,
  Bell,
  Medal
} from 'lucide-vue-next'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { AclEnum } from '@/enums/acl/AclEnum'
import { Button } from '@/components/ui/button'

import TooltipProvider from '@/components/ui/tooltip/TooltipProvider.vue'
import Tooltip from '@/components/ui/tooltip/Tooltip.vue'
import TooltipTrigger from '@/components/ui/tooltip/TooltipTrigger.vue'
import TooltipContent from '@/components/ui/tooltip/TooltipContent.vue'
import { useAuth } from '@/composables/useAuth'
import { computed, onMounted, ref } from 'vue'
import { useTenant } from '@/composables/useTenant'
import { useNotification } from '@/composables/useNotification'
import NotificationCard from '@/views/notifications/NotificationCard.vue'
import { NotificationStatusEnum } from '@/enums/notifications/NotificationStatusEnum'


export default /*@__PURE__*/_defineComponent({
  __name: 'PanelLayout',
  setup(__props) {

const isNotificationDropdownOpen = ref(false)
const { tenantLogo, initializeTenant } = useTenant()

const unreadCount = computed(() => notifications.value?.data.filter(n => n.status === NotificationStatusEnum.UNREAD).length)

const { logout, userAuthenticated, hasRoles } = useAuth()
const { notifications, initializeNotifications, readAllNotifications } = useNotification()

onMounted(() => {
  initializeTenant()
  initializeNotifications()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        (_unref(hasRoles)([_unref(AclEnum).PRODUCER, _unref(AclEnum).ADMIN]))
          ? (_openBlock(), _createBlock(TooltipProvider, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(Tooltip, { "delay-duration": 10 }, {
                  default: _withCtx(() => [
                    _createVNode(TooltipTrigger, { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_RouterLink, {
                          to: { name: 'courses.index' },
                          class: "rounded-lg",
                          "exact-active-class": "bg-muted"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              class: "rounded-lg",
                              "aria-label": "Cursos",
                              "exact-active-class": "bg-muted"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Book), {
                                  class: "size-7",
                                  "stroke-width": 1.25
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(TooltipContent, {
                      side: "right",
                      "side-offset": 5
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Cursos ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'courses.my' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Meus cursos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(LibraryBig), {
                              class: "size-7",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Meus cursos ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'feed' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Comunidade"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Users), {
                              class: "size-7",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Comunidade ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'chat' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Ranking"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(MessageSquare), {
                              class: "size-7",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Chat ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'affiliates' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Ranking"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Waypoints), {
                              class: "size-7",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Afiliados ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'traders' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Ranking"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Handshake), {
                              class: "size-7",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Traders ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: 'ranking' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Ranking"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Medal), {
                              class: "size-7",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Ranking ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(TooltipProvider, null, {
          default: _withCtx(() => [
            _createVNode(Tooltip, { "delay-duration": 100 }, {
              default: _withCtx(() => [
                _createVNode(TooltipTrigger, { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: { name: _unref(hasRoles)([_unref(AclEnum).PRODUCER, _unref(AclEnum).ADMIN]) ? 'tournaments.manage' : 'tournaments' },
                      class: "rounded-lg",
                      "exact-active-class": "bg-muted"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon",
                          class: "rounded-lg",
                          "aria-label": "Torneios"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Trophy), {
                              class: "size-7",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _: 1
                }),
                _createVNode(TooltipContent, {
                  side: "right",
                  "side-offset": 5
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Torneios ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _cache[14] || (_cache[14] = _createElementVNode("nav", { class: "mt-auto grid gap-1 p-2" }, null, -1))
    ]),
    _createElementVNode("header", _hoisted_4, [
      _createElementVNode("nav", _hoisted_5, [
        _createElementVNode("a", _hoisted_6, [
          (_unref(tenantLogo))
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _unref(tenantLogo).bucketLocation,
                height: "28",
                width: "28"
              }, null, 8, _hoisted_7))
            : _createCommentVNode("", true)
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("a", {
          href: "/#",
          class: "text-foreground transition-colors hover:text-foreground"
        }, null, -1))
      ]),
      _createVNode(_unref(Sheet), null, {
        default: _withCtx(() => [
          _createVNode(_unref(SheetTrigger), { "as-child": "" }, {
            default: _withCtx(() => [
              _createVNode(_unref(Button), {
                variant: "outline",
                size: "icon",
                class: "shrink-0 md:hidden"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Menu), { class: "h-5 w-5" }),
                  _cache[16] || (_cache[16] = _createElementVNode("span", { class: "sr-only" }, "Toggle navigation menu", -1))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(SheetContent), { side: "left" }, {
            default: _withCtx(() => [
              _createElementVNode("nav", _hoisted_8, [
                _createElementVNode("a", _hoisted_9, [
                  _createVNode(_unref(Package2), { class: "h-6 w-6" })
                ]),
                _createVNode(_component_RouterLink, {
                  to: { name: 'courses.index' },
                  class: "hover:text-foreground px-3 py-2 rounded-lg",
                  "exact-active-class": "bg-muted"
                }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode(" Cursos ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_RouterLink, {
                  to: { name: 'courses.my' },
                  class: "hover:text-foreground px-3 py-2 rounded-lg",
                  "exact-active-class": "bg-muted"
                }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode(" Meus cursos ")
                  ])),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_10, [
        _cache[23] || (_cache[23] = _createElementVNode("form", { class: "ml-auto flex-1 sm:flex-initial" }, null, -1)),
        _createVNode(_unref(DropdownMenu), {
          open: isNotificationDropdownOpen.value,
          "onUpdate:open": [
            _cache[1] || (_cache[1] = ($event: any) => ((isNotificationDropdownOpen).value = $event)),
            _cache[2] || (_cache[2] = ($event: any) => (isNotificationDropdownOpen.value = $event))
          ]
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  variant: "secondary",
                  size: "icon",
                  class: "rounded-full relative"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Bell), { class: "h-5 w-5" }),
                    (unreadCount.value && unreadCount.value > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(unreadCount.value), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(DropdownMenuContent), {
              class: "w-80",
              align: "end"
            }, {
              default: _withCtx(() => [
                _createVNode(NotificationCard, {
                  onShowMore: _cache[0] || (_cache[0] = () => { _ctx.$router.push({ name: 'notifications' }); isNotificationDropdownOpen.value = false }),
                  "has-scroll-area": true
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]),
        _createVNode(_unref(DropdownMenu), null, {
          default: _withCtx(() => [
            _createVNode(_unref(DropdownMenuTrigger), { "as-child": "" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Button), {
                  variant: "secondary",
                  size: "icon",
                  class: "rounded-full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CircleUser), { class: "h-5 w-5" }),
                    _cache[19] || (_cache[19] = _createElementVNode("span", { class: "sr-only" }, "Toggle user menu", -1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(DropdownMenuContent), { align: "end" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DropdownMenuItem), {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'profile', params: { profileId: _unref(userAuthenticated).userId }  })))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(UserCog), { class: "h-4 w-4 mr-2" }),
                    _cache[20] || (_cache[20] = _createTextVNode(" Minha Conta"))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DropdownMenuSeparator)),
                _createVNode(_unref(DropdownMenuItem), {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({ name: 'terms-of-use' })))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(HelpCircle), { class: "h-4 w-4 mr-2" }),
                    _cache[21] || (_cache[21] = _createTextVNode(" Regras e Contrato "))
                  ]),
                  _: 1
                }),
                _createVNode(_unref(DropdownMenuSeparator)),
                _createVNode(_unref(DropdownMenuItem), {
                  class: "cursor-pointer",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(logout)(
              () => _ctx.$router.push({ name: 'login' })
            )))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(LogOut), { class: "h-4 w-4 mr-2" }),
                    _cache[22] || (_cache[22] = _createTextVNode(" Sair "))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_RouterView, { class: "max-w-[1800px] w-full" })
    ])
  ]))
}
}

})