import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "h-48 bg-gray-400 relative" }
const _hoisted_2 = {
  key: 2,
  for: "banner",
  class: "absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { for: "avatar" }
const _hoisted_5 = { class: "pt-16 px-4" }
const _hoisted_6 = { class: "flex justify-between items-start mb-4" }
const _hoisted_7 = { class: "text-2xl font-bold" }
const _hoisted_8 = {
  key: 0,
  class: "grid gap-2"
}
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = { class: "flex justify-between mb-6" }
const _hoisted_11 = { class: "text-center" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = { class: "mb-6" }
const _hoisted_14 = { class: "flex flex-wrap gap-2" }
const _hoisted_15 = { class: "font-semibold" }
const _hoisted_16 = {
  key: 1,
  class: "p-4 space-y-4"
}
const _hoisted_17 = { class: "p-4 space-y-4" }
const _hoisted_18 = { class: "flex justify-between" }
const _hoisted_19 = { class: "p-4 grid grid-cols-3 gap-2" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "space-y-4" }
const _hoisted_22 = { class: "space-y-2" }
const _hoisted_23 = { class: "space-y-2" }
const _hoisted_24 = { class: "space-y-2" }
const _hoisted_25 = { class: "space-y-4" }
const _hoisted_26 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_27 = { class: "space-y-2" }
const _hoisted_28 = { class: "space-y-2" }
const _hoisted_29 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_30 = { class: "space-y-2" }
const _hoisted_31 = { class: "space-y-2" }
const _hoisted_32 = { class: "space-y-4" }
const _hoisted_33 = { class: "space-y-2" }
const _hoisted_34 = { class: "space-y-2" }
const _hoisted_35 = { class: "space-y-2" }

import { computed, onMounted, ref } from "vue";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  TrendingUpIcon,
  AwardIcon,
  BarChart4Icon,
  StarIcon,
  ZapIcon,
  Pencil,
  ImageUp,
  Loader2,
} from "lucide-vue-next";
import { useToast } from "@/components/ui/toast";
import { Profile } from "@/interfaces/profiles/Profile";
import { ProfileMediaTypeEnum } from "@/enums/profiles/ProfileMediaTypeEnum";
import { useAxios } from "@/composables/useAxios";
import { ProfileTopic } from "@/interfaces/profiles/profileTopics/ProfileTopic";
import { Pagination } from "@/interfaces/Pagination";
import ProfileTopicComponent from "./ProfileTopicComponent.vue";
import PaginationApi from "@/components/ui/pagination/PaginationApi.vue";
import { vMaska } from "maska/vue"
import { useAuth } from "@/composables/useAuth";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileBox',
  props: {
    user: {}
  },
  emits: ['update'],
  setup(__props: any, { emit: __emit }) {

const { userAuthenticated, changePassword, isChangingPassword } = useAuth()
const emit = __emit
const props = __props;
const { toast } = useToast();
const axios = useAxios()
const avatar = computed(() =>
  props.user.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const banner = computed(() =>
  props.user.media.find((m) => m.type === ProfileMediaTypeEnum.BANNER)
);
const avatarPreview = ref<string>()
const bannerPreview = ref<string>()
const isEditModalOpen = ref(false);
const isUpdatingProfile = ref(false);
const editForm = ref({
  bank: "",
  agency: "",
  account: "",
  document: "",
  notifications: {
    email: false,
    push: false,
    sms: false,
  },
});
const changePasswordPayload = ref({
  password: '',
  passwordConfirm: '',
  passwordOld: '',
})
const profilePayload = ref<{
  avatar?: File
  banner?: File
  name?: string
  description?: string,
  phoneNumber?: string
}>({
  name: props.user.name,
  description: props.user.description,
  phoneNumber: props.user.phoneNumber,
})
const topics = ref<Pagination<ProfileTopic>>()
const topicPayload = ref<{ content: string }>({ content: '' })
const isCreatingTopic = ref(false)

// const user = ref({
//     name: 'Jane Doe',
//     avatar: 'https://example.com/avatar.jpg',
//     coverPhoto: 'https://example.com/cover.jpg',
//     description: 'Professional trader and social media enthusiast',
//     posts: 245,
//     followers: '10.5K',
//     // following: 1024,
//     achievements: ['Top Trader', '1000 Trades', '5 Star Rating'],
//     tradingStats: {
//         winRate: 68,
//         totalTrades: 1532,
//         avgProfit: 2.3,
//         avgLoss: 1.1
//     },
//     recentPosts: [
//         { id: 1, content: 'Just closed another successful trade! #trading #success', date: '2h ago' },
//         { id: 2, content: 'Check out my latest market analysis video', date: '1d ago' },
//     ],
//     recentTrades: [
//         { id: 1, pair: 'BTC/USD', type: 'Buy', profitLoss: 2.5, date: '3h ago' },
//         { id: 2, pair: 'ETH/USD', type: 'Sell', profitLoss: -1.2, date: '1d ago' },
//         { id: 3, pair: 'XRP/USD', type: 'Buy', profitLoss: 3.7, date: '2d ago' },
//     ],
//     recentMedia: [
//         'https://example.com/media1.jpg',
//         'https://example.com/media2.jpg',
//         'https://example.com/media3.jpg',
//         'https://example.com/media4.jpg',

//         'https://example.com/media5.jpg',
//         'https://example.com/media6.jpg',
//     ]
// })

const achievements = [
  {
    name: "Top Trader",
    description: "Ranked in the top 1% of traders on the platform",
    icon: TrendingUpIcon,
  },
  {
    name: "1000 Trades",
    description: "Successfully completed 1000 trades",
    icon: BarChart4Icon,
  },
  {
    name: "5 Star Rating",
    description: "Maintained a 5-star rating for over 6 months",
    icon: StarIcon,
  },
  {
    name: "Winning Streak",
    description: "Achieved a winning streak of 10 profitable trades in a row",
    icon: ZapIcon,
  },
  {
    name: "Master Analyst",
    description: "Provided top-rated market analysis for 3 consecutive months",
    icon: AwardIcon,
  },
];

const openEditModal = () => {
  editForm.value = {
    bank: "",
    agency: "",
    account: "",
    document: "",
    notifications: {
      email: false,
      push: false,
      sms: false,
    },
  };
  isEditModalOpen.value = true;
};

const updateBankDetails = () => {
  // Here you would typically send the bank details to your backend
  toast({
    title: "Success",
    description: "Bank details updated successfully",
  });
  isEditModalOpen.value = false;
};

const updateNotifications = () => {
  // Here you would typically send the notification preferences to your backend
  toast({
    title: "Success",
    description: "Notification preferences updated successfully",
  });
  isEditModalOpen.value = false;
};

function onAvatarChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  profilePayload.value.avatar = file

  avatarPreview.value = URL.createObjectURL(file)
}

function onBannerChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  profilePayload.value.banner = file

  bannerPreview.value = URL.createObjectURL(file)
}

function updateProfile() {
  isUpdatingProfile.value = true

  const formData = new FormData()

  for (const key in profilePayload.value) {
    if (profilePayload.value[key as keyof typeof profilePayload.value]) {
      formData.append(key, profilePayload.value[key as keyof typeof profilePayload.value] as any)
    }
  }

  axios.patch(`/profile`, formData)
    .then(() => {
      toast({
        title: 'Perfil atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })
      emit('update')
      isEditModalOpen.value = false;
    })
    .finally(() => isUpdatingProfile.value = false)
}

function createTopic() {
  isCreatingTopic.value = true

  axios.post(`/profile/${props.user.id}/topics`, topicPayload.value)
    .then((res) => {
      getTopics()
      topicPayload.value = { content: '' }
    })
    .finally(() => isCreatingTopic.value = false)
}

function getTopics(page = 1) {
  axios.get(`/profile/${props.user.id}/topics`, { params: { page } })
    .then((res) => {
      topics.value = res.data
    })
}

onMounted(() => {
  getTopics()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card), { class: "w-full" }, {
    default: _withCtx(() => [
      _createVNode(_unref(CardContent), { class: "p-0" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_unref(userAuthenticated).userId === _ctx.user.userId)
              ? (_openBlock(), _createElementBlock("input", {
                  key: 0,
                  class: "hidden",
                  accept: "image/png, image/jpg, image/jpeg, image/gif, image/avif",
                  type: "file",
                  onChange: onAvatarChange,
                  id: "avatar"
                }, null, 32))
              : _createCommentVNode("", true),
            (_unref(userAuthenticated).userId === _ctx.user.userId)
              ? (_openBlock(), _createElementBlock("input", {
                  key: 1,
                  class: "hidden",
                  accept: "image/png, image/jpg, image/jpeg, image/gif, image/avif",
                  type: "file",
                  onChange: onBannerChange,
                  id: "banner"
                }, null, 32))
              : _createCommentVNode("", true),
            (_unref(userAuthenticated).userId === _ctx.user.userId)
              ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
                  (
              !banner.value?.bucketLocation &&
              !bannerPreview.value
            )
                    ? (_openBlock(), _createBlock(_unref(ImageUp), {
                        key: 0,
                        class: "w-4 h-4"
                      }))
                    : (_openBlock(), _createBlock(_unref(Pencil), {
                        key: 1,
                        class: "w-4 h-4"
                      }))
                ]))
              : _createCommentVNode("", true),
            (banner.value || bannerPreview.value)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 3,
                  src: bannerPreview.value ? bannerPreview.value : banner.value!.bucketLocation,
                  alt: "Cover Photo",
                  class: "w-full h-full object-cover"
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            _createElementVNode("label", _hoisted_4, [
              _createVNode(_unref(Avatar), {
                class: "absolute bottom-0 left-4 transform translate-y-1/2 border-4 border-white",
                size: "lg"
              }, {
                default: _withCtx(() => [
                  (avatar.value?.bucketLocation || avatarPreview.value)
                    ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                        key: 0,
                        src: avatarPreview.value ? avatarPreview.value : avatar.value!.bucketLocation
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(AvatarFallback), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.user.name
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase())
                .join("")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.user.name), 1)
              ]),
              (_unref(userAuthenticated).userId === _ctx.user.userId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_unref(Button), { onClick: openEditModal }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode("Editar perfil")
                      ])),
                      _: 1
                    }),
                    (!!profilePayload.value.avatar || !!profilePayload.value.banner)
                      ? (_openBlock(), _createBlock(_unref(Button), {
                          key: 0,
                          onClick: updateProfile,
                          class: "bg-emerald-500 text-white hover:bg-emerald-600"
                        }, {
                          default: _withCtx(() => [
                            (isUpdatingProfile.value)
                              ? (_openBlock(), _createBlock(_unref(Loader2), {
                                  key: 0,
                                  class: "w-4 h-4 mr-2 animate-spin"
                                }))
                              : _createCommentVNode("", true),
                            _cache[15] || (_cache[15] = _createTextVNode(" Salvar alterações "))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.user.description), 1),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, _toDisplayString(topics.value?.pagination.total), 1),
                _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-sm text-gray-500" }, "Publicações", -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _cache[17] || (_cache[17] = _createElementVNode("h3", { class: "font-semibold mb-2" }, "Conquistas", -1)),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_unref(TooltipProvider), null, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(achievements, (achievement) => {
                      return _createVNode(_unref(Tooltip), {
                        key: achievement.name
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(TooltipTrigger), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Badge), {
                                variant: "secondary",
                                class: "p-2"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(achievement.icon), { class: "h-5 w-5" }))
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(TooltipContent), null, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_15, _toDisplayString(achievement.name), 1),
                              _createElementVNode("p", null, _toDisplayString(achievement.description), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    }), 64))
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createVNode(_unref(Tabs), {
            "default-value": "posts",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(TabsList), { class: "w-full" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(TabsTrigger), {
                    value: "posts",
                    class: "flex-1"
                  }, {
                    default: _withCtx(() => _cache[18] || (_cache[18] = [
                      _createTextVNode("Publicações")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(TabsTrigger), {
                    value: "trades",
                    class: "flex-1"
                  }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("Trades Recentes")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(TabsContent), { value: "posts" }, {
                default: _withCtx(() => [
                  (_unref(userAuthenticated).userId === _ctx.user.userId)
                    ? (_openBlock(), _createBlock(_unref(Card), {
                        key: 0,
                        class: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(CardHeader), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                                default: _withCtx(() => _cache[20] || (_cache[20] = [
                                  _createTextVNode("Criar Publicação")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(CardContent), { class: "grid gap-6" }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Textarea), {
                                modelValue: topicPayload.value.content,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((topicPayload.value.content) = $event)),
                                placeholder: "No que você está pensando?"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(CardFooter), { class: "flex justify-between" }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Button), {
                                disabled: !topicPayload.value.content || isCreatingTopic.value,
                                onClick: createTopic
                              }, {
                                default: _withCtx(() => [
                                  (isCreatingTopic.value)
                                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                                        key: 0,
                                        class: "w-4 h-4 mr-2 animate-spin"
                                      }))
                                    : _createCommentVNode("", true),
                                  _cache[21] || (_cache[21] = _createTextVNode(" Publicar "))
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!!topics.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topics.value.data, (topic) => {
                          return (_openBlock(), _createBlock(ProfileTopicComponent, {
                            profile: _ctx.user,
                            key: topic.id,
                            topic: topic,
                            onDelete: ($event: any) => (topics.value.data = topics.value.data.filter((t) => t.id !== topic.id))
                          }, null, 8, ["profile", "topic", "onDelete"]))
                        }), 128)),
                        _createVNode(PaginationApi, {
                          data: topics.value.pagination,
                          paginate: getTopics
                        }, null, 8, ["data"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_unref(TabsContent), { value: "trades" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.recentTrades, (trade) => {
                      return (_openBlock(), _createBlock(_unref(Card), {
                        key: trade.id
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(CardHeader), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(CardTitle), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(trade.pair), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(CardDescription), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(trade.date), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_unref(CardContent), null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", null, [
                                  _cache[22] || (_cache[22] = _createElementVNode("p", { class: "text-sm text-gray-500" }, "Type", -1)),
                                  _createElementVNode("p", {
                                    class: _normalizeClass({
                        'text-green-600': trade.type === 'Buy',
                        'text-red-600': trade.type === 'Sell',
                      })
                                  }, _toDisplayString(trade.type), 3)
                                ]),
                                _createElementVNode("div", null, [
                                  _cache[23] || (_cache[23] = _createElementVNode("p", { class: "text-sm text-gray-500" }, "Profit/Loss", -1)),
                                  _createElementVNode("p", {
                                    class: _normalizeClass({
                        'text-green-600': trade.profitLoss > 0,
                        'text-red-600': trade.profitLoss < 0,
                      })
                                  }, _toDisplayString(trade.profitLoss > 0 ? "+" : "") + _toDisplayString(trade.profitLoss) + "% ", 3)
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_unref(TabsContent), { value: "media" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_19, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user.recentMedia, (media, index) => {
                      return (_openBlock(), _createElementBlock("img", {
                        key: index,
                        src: media,
                        alt: "Media",
                        class: "w-full h-32 object-cover rounded"
                      }, null, 8, _hoisted_20))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_unref(userAuthenticated).userId === _ctx.user.userId)
        ? (_openBlock(), _createBlock(_unref(Dialog), {
            key: 0,
            open: isEditModalOpen.value,
            "onUpdate:open": _cache[13] || (_cache[13] = ($event: any) => ((isEditModalOpen).value = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(DialogContent), { class: "w-full max-w-[800px] grid-rows-[auto_minmax(0,1fr)_auto]" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogHeader), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DialogTitle), null, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode("Editar perfil")
                        ])),
                        _: 1
                      }),
                      _createVNode(_unref(DialogDescription), null, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode(" Atualize as informações do seu perfil, dados bancários, senha, e preferências de notificação. ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(Tabs), {
                    "default-value": "profile",
                    class: "w-full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TabsList), { class: "grid w-full grid-cols-3" }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(TabsTrigger), { value: "profile" }, {
                            default: _withCtx(() => _cache[26] || (_cache[26] = [
                              _createTextVNode("Perfil")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(TabsTrigger), { value: "bank" }, {
                            default: _withCtx(() => _cache[27] || (_cache[27] = [
                              _createTextVNode("dados bancários")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(TabsTrigger), { value: "password" }, {
                            default: _withCtx(() => _cache[28] || (_cache[28] = [
                              _createTextVNode("Senha")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(TabsContent), { value: "profile" }, {
                        default: _withCtx(() => [
                          _createElementVNode("form", {
                            onSubmit: _withModifiers(updateProfile, ["prevent"])
                          }, [
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createVNode(_unref(Label), { for: "name" }, {
                                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                                    _createTextVNode("Nome")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Input), {
                                  id: "name",
                                  modelValue: profilePayload.value.name,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((profilePayload.value.name) = $event)),
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                _createVNode(_unref(Label), { for: "phoneNumber" }, {
                                  default: _withCtx(() => _cache[30] || (_cache[30] = [
                                    _createTextVNode("Número de telefone")
                                  ])),
                                  _: 1
                                }),
                                _withDirectives(_createVNode(_unref(Input), {
                                  type: "tel",
                                  id: "phoneNumber",
                                  modelValue: profilePayload.value.phoneNumber,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((profilePayload.value.phoneNumber) = $event)),
                                  required: ""
                                }, null, 8, ["modelValue"]), [
                                  [_unref(vMaska), '(##) # ####-####']
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_24, [
                                _createVNode(_unref(Label), { for: "description" }, {
                                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                                    _createTextVNode("Descrição")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Textarea), {
                                  id: "description",
                                  modelValue: profilePayload.value.description,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((profilePayload.value.description) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _createVNode(_unref(Button), {
                                onClick: updateProfile,
                                type: "submit",
                                disabled: !profilePayload.value.name ||
                !profilePayload.value.description ||
                (!!profilePayload.value.phoneNumber && profilePayload.value.phoneNumber?.length < 16)
                              }, {
                                default: _withCtx(() => [
                                  (isUpdatingProfile.value)
                                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                                        key: 0,
                                        class: "w-4 h-4 mr-2 animate-spin"
                                      }))
                                    : _createCommentVNode("", true),
                                  _cache[32] || (_cache[32] = _createTextVNode(" Salvar "))
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ])
                          ], 32)
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(TabsContent), { value: "bank" }, {
                        default: _withCtx(() => [
                          _createElementVNode("form", {
                            onSubmit: _withModifiers(updateBankDetails, ["prevent"])
                          }, [
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _createElementVNode("div", _hoisted_27, [
                                  _createVNode(_unref(Label), { for: "bank" }, {
                                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                                      _createTextVNode("Bank")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Input), {
                                    id: "bank",
                                    modelValue: editForm.value.bank,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((editForm.value.bank) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_28, [
                                  _createVNode(_unref(Label), { for: "agency" }, {
                                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                                      _createTextVNode("Agency")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Input), {
                                    id: "agency",
                                    modelValue: editForm.value.agency,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((editForm.value.agency) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_29, [
                                _createElementVNode("div", _hoisted_30, [
                                  _createVNode(_unref(Label), { for: "account" }, {
                                    default: _withCtx(() => _cache[35] || (_cache[35] = [
                                      _createTextVNode("Account")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Input), {
                                    id: "account",
                                    modelValue: editForm.value.account,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((editForm.value.account) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _createElementVNode("div", _hoisted_31, [
                                  _createVNode(_unref(Label), { for: "document" }, {
                                    default: _withCtx(() => _cache[36] || (_cache[36] = [
                                      _createTextVNode("Document Number")
                                    ])),
                                    _: 1
                                  }),
                                  _createVNode(_unref(Input), {
                                    id: "document",
                                    modelValue: editForm.value.document,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((editForm.value.document) = $event)),
                                    required: ""
                                  }, null, 8, ["modelValue"])
                                ])
                              ]),
                              _createVNode(_unref(Button), { type: "submit" }, {
                                default: _withCtx(() => _cache[37] || (_cache[37] = [
                                  _createTextVNode("Update Bank Details")
                                ])),
                                _: 1
                              })
                            ])
                          ], 32)
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(TabsContent), { value: "password" }, {
                        default: _withCtx(() => [
                          _createElementVNode("form", {
                            onSubmit: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["prevent"]))
                          }, [
                            _createElementVNode("div", _hoisted_32, [
                              _createElementVNode("div", _hoisted_33, [
                                _createVNode(_unref(Label), { for: "current-password" }, {
                                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                                    _createTextVNode("Senha atual")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Input), {
                                  id: "current-password",
                                  type: "password",
                                  modelValue: changePasswordPayload.value.passwordOld,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((changePasswordPayload.value.passwordOld) = $event)),
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_34, [
                                _createElementVNode("div", null, [
                                  _createVNode(_unref(Label), { for: "new-password" }, {
                                    default: _withCtx(() => _cache[39] || (_cache[39] = [
                                      _createTextVNode("Nova senha")
                                    ])),
                                    _: 1
                                  }),
                                  _cache[40] || (_cache[40] = _createElementVNode("p", { class: "text-xs text-zinc-500" }, "Deve conter no mínimo 8 caracteres", -1))
                                ]),
                                _createVNode(_unref(Input), {
                                  id: "new-password",
                                  type: "password",
                                  modelValue: changePasswordPayload.value.password,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((changePasswordPayload.value.password) = $event)),
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_35, [
                                _createVNode(_unref(Label), { for: "confirm-password" }, {
                                  default: _withCtx(() => _cache[41] || (_cache[41] = [
                                    _createTextVNode("Confirmar nova senha")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(Input), {
                                  id: "confirm-password",
                                  type: "password",
                                  modelValue: changePasswordPayload.value.passwordConfirm,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((changePasswordPayload.value.passwordConfirm) = $event)),
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _createVNode(_unref(Button), {
                                disabled: 
                _unref(isChangingPassword) || 
                !changePasswordPayload.value.passwordOld || 
                !changePasswordPayload.value.password || 
                !changePasswordPayload.value.passwordConfirm || 
                changePasswordPayload.value.password !== changePasswordPayload.value.passwordConfirm ||
                changePasswordPayload.value.password.length < 8 ||
                changePasswordPayload.value.passwordConfirm.length < 8,
                                onClick: _cache[11] || (_cache[11] = ($event: any) => (_unref(changePassword)(
                    changePasswordPayload.value, 
                    () => {
                      _unref(toast)({
                        title: 'Senha alterada com sucesso',
                        class: 'bg-green-500 text-white'
                      })
                      changePasswordPayload.value = {
                        passwordOld: '',
                        password: '',
                        passwordConfirm: '',
                      }
                    }, 
                    () => _unref(toast)({
                      title: 'Senha atual incorreta',
                      description: 'Tente novamente',
                      variant: 'destructive'
                    })
                  ))),
                                type: "submit"
                              }, {
                                default: _withCtx(() => [
                                  (_unref(isChangingPassword))
                                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                                        key: 0,
                                        class: "w-4 h-4 mr-2 animate-spin"
                                      }))
                                    : _createCommentVNode("", true),
                                  _cache[42] || (_cache[42] = _createTextVNode(" Salvar "))
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ])
                          ], 32)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["open"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})