import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "min-h-screen text-white p-8"
}
const _hoisted_2 = { class: "max-w-4xl mx-auto bg-card rounded-lg overflow-hidden" }
const _hoisted_3 = { class: "relative h-64 bg-gradient-to-r from-blue-600 to-emerald-600" }
const _hoisted_4 = { class: "absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center" }
const _hoisted_5 = { class: "text-4xl font-bold text-white" }
const _hoisted_6 = { class: "p-8" }
const _hoisted_7 = { class: "flex flex-wrap items-center justify-between mb-8" }
const _hoisted_8 = { class: "flex items-center space-x-4 mb-4 sm:mb-0" }
const _hoisted_9 = { class: "flex items-center text-yellow-400" }
const _hoisted_10 = { class: "text-gray-300 mb-8" }
const _hoisted_11 = { class: "grid grid-cols-1 md:grid-cols-2 gap-6 mb-8" }
const _hoisted_12 = { class: "bg-zinc-700 p-4 rounded-lg" }
const _hoisted_13 = { class: "text-lg font-semibold mb-2 flex items-center" }
const _hoisted_14 = { class: "text-gray-300" }
const _hoisted_15 = { class: "text-gray-300" }
const _hoisted_16 = { class: "bg-zinc-700 p-4 rounded-lg" }
const _hoisted_17 = { class: "text-lg font-semibold mb-2 flex items-center" }
const _hoisted_18 = { class: "text-gray-300" }
const _hoisted_19 = { class: "mt-2 flex -space-x-2" }
const _hoisted_20 = {
  key: 0,
  class: "flex items-center justify-center w-8 h-8 rounded-full bg-gray-600 border-2 border-gray-800 text-xs font-medium"
}
const _hoisted_21 = { class: "mb-8" }
const _hoisted_22 = { class: "bg-zinc-700 rounded-lg overflow-hidden" }
const _hoisted_23 = { class: "w-full" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "py-3 px-4" }
const _hoisted_26 = { class: "py-3 px-4 flex items-center" }
const _hoisted_27 = { class: "px-4" }

import { onMounted, ref } from 'vue'
import { format } from 'date-fns'
import { ArrowLeft as ArrowLeftIcon, Trophy as TrophyIcon, Calendar as CalendarIcon, Users as UsersIcon } from 'lucide-vue-next'
import { Tournament } from '@/interfaces/tournaments/Tournament';
import { TournamentParticipant } from '@/interfaces/tournaments/TournamentParticipant';
import { useAxios } from '@/composables/useAxios';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { Pagination } from '@/interfaces/Pagination';
import { TournamentStatusEnum, tournamentStatusLabels } from '@/enums/tournaments/TournamentStatusEnum';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import PrizeList from './components/PrizeList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowTournamentView',
  props: {
    tournamentId: {}
  },
  setup(__props: any) {

const props = __props
const tournament = ref<Tournament>()
const tournamentParticipants = ref<Pagination<TournamentParticipant>>()
const axios = useAxios()

const formatDate = (date: Date) => {
  return format(date, "MMMM d, yyyy")
}

const getRandomProfit = () => {
  return (Math.random() * 200 - 100).toFixed(2)
}

const formatProfit = (profit: number) => {
  return profit > 0 ? `+${profit}%` : `${profit}%`
}

const getPaymentStatus = (status: number) => {
  switch (status) {
    case 1: return 'Paid'
    case 0: return 'Pending'
    case -1: return 'Failed'
    default: return 'Unknown'
  }
}

function getTournament() {
  axios.get(`/tournaments/${props.tournamentId}`)
    .then((res) => {
      tournament.value = res.data
    })
}

function getTournamentParticipants() {
  axios.get(`/tournaments/${props.tournamentId}/participants`)
    .then((res) => {
      tournamentParticipants.value = res.data
    })
}

onMounted(() => {
  getTournament()
  getTournamentParticipants()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (tournament.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h1", _hoisted_5, _toDisplayString(tournament.value.name), 1)
            ]),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
              class: "absolute top-4 left-4 bg-white bg-opacity-20 p-2 rounded-full hover:bg-opacity-30 transition-all duration-300"
            }, [
              _createVNode(_unref(ArrowLeftIcon), { class: "h-6 w-6 text-white" })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", {
                  class: _normalizeClass([{
              'bg-emerald-500': tournament.value.status === _unref(TournamentStatusEnum).FINISHED,
              'bg-blue-500': tournament.value.status === _unref(TournamentStatusEnum).OPEN,
              'bg-red-500': tournament.value.status === _unref(TournamentStatusEnum).CLOSED
            }, "px-3 py-1 rounded-full text-sm font-semibold"])
                }, _toDisplayString(_unref(tournamentStatusLabels)[tournament.value.status]), 3),
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_unref(TrophyIcon), { class: "h-5 w-5 mr-2" }),
                  _createTextVNode(" " + _toDisplayString(tournament.value.prize), 1)
                ])
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("button", { class: "bg-gradient-to-r from-emerald-500 to-blue-500 text-white font-bold py-2 px-6 rounded-full hover:from-emerald-600 hover:to-blue-600 transition-all duration-300 transform hover:scale-105" }, " Se juntar ao torneio ", -1))
            ]),
            _createElementVNode("p", _hoisted_10, _toDisplayString(tournament.value.description), 1),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h3", _hoisted_13, [
                  _createVNode(_unref(CalendarIcon), { class: "h-5 w-5 mr-2 text-emerald-400" }),
                  _cache[2] || (_cache[2] = _createTextVNode(" Datas do torneio "))
                ]),
                _createElementVNode("p", _hoisted_14, "Começa em: " + _toDisplayString(formatDate(tournament.value.startAt)), 1),
                _createElementVNode("p", _hoisted_15, "Termina em: " + _toDisplayString(formatDate(tournament.value.endAt)), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("h3", _hoisted_17, [
                  _createVNode(_unref(UsersIcon), { class: "h-5 w-5 mr-2 text-blue-400" }),
                  _cache[3] || (_cache[3] = _createTextVNode(" Participantes "))
                ]),
                _createElementVNode("p", _hoisted_18, _toDisplayString(tournamentParticipants.value?.pagination.total) + " aluno(s)", 1),
                _createElementVNode("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tournamentParticipants.value?.data, (participant, index) => {
                    return (_openBlock(), _createBlock(_unref(Avatar), {
                      key: participant.id
                    }, {
                      default: _withCtx(() => [
                        (participant.profile.avatar)
                          ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                              key: 0,
                              src: participant.profile.avatar.bucketLocation,
                              alt: participant.profile.name
                            }, null, 8, ["src", "alt"]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(AvatarFallback), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(participant.profile.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (tournament.value.participantCount > 5)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, " +" + _toDisplayString(tournament.value.participantCount - 5), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            (tournament.value.prizes.length)
              ? (_openBlock(), _createBlock(PrizeList, {
                  key: 0,
                  prizes: tournament.value.prizes
                }, null, 8, ["prizes"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_21, [
              _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-xl font-semibold mb-4" }, "Ranking", -1)),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("table", _hoisted_23, [
                  _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", { class: "bg-gray-600" }, [
                      _createElementVNode("th", { class: "py-2 px-4 text-left" }, "Rank"),
                      _createElementVNode("th", { class: "py-2 px-4 text-left" }, "Trader"),
                      _createElementVNode("th", { class: "py-2 px-4 text-left" }, "Nome")
                    ])
                  ], -1)),
                  (tournamentParticipants.value)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_24, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tournamentParticipants.value?.data, (participant, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: participant.id,
                            class: "border-b border-gray-600 last:border-b-0"
                          }, [
                            _createElementVNode("td", _hoisted_25, _toDisplayString(index + 1), 1),
                            _createElementVNode("td", _hoisted_26, [
                              _createVNode(_component_RouterLink, {
                                to: { name: 'profile', params: { profileId: participant.userId } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Avatar), null, {
                                    default: _withCtx(() => [
                                      (participant.profile.avatar)
                                        ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                                            key: 0,
                                            src: participant.profile.avatar.bucketLocation,
                                            alt: participant.profile.name
                                          }, null, 8, ["src", "alt"]))
                                        : _createCommentVNode("", true),
                                      _createVNode(_unref(AvatarFallback), null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(participant.profile.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]),
                            _createElementVNode("td", _hoisted_27, _toDisplayString(participant.profile.name), 1)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
}
}

})