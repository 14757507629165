import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sm:container w-full mx-auto p-4 space-y-6" }
const _hoisted_2 = {
  key: 0,
  class: "grid sm:grid-cols-2 md:grid-cols-3 gap-6"
}
const _hoisted_3 = { class: "md:col-span-2" }
const _hoisted_4 = { class: "space-y-9" }

import { ref, onMounted } from 'vue'
import { Toaster } from '@/components/ui/toast'
import ProfileBox from './components/ProfileBox.vue'
import PerformanceChart from './components/PerformanceChart.vue'
import { useAxios } from '@/composables/useAxios'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import CommentsSection from './components/commentsSection/CommentsSection.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileView',
  props: {
    profileId: {}
  },
  setup(__props: any) {

const props = __props
const axios = useAxios()
const user = ref()

function getProfile() {
  axios.get(`/profile/${props.profileId}`)
    .then((res) => {
      user.value = { ...res.data, phoneNumber: res.data.phoneNumber?.slice(2,) }
    })
}

onMounted(async () => {
  getProfile()
  // const response = await fetch('/api/user/profile.js')
  // user.value = await response.json()
  // user.value = {
  //   id: 1,
  //   name: 'John Doe',
  //   avatar: 'https://example.com/avatar.jpg',
  //   initials: 'JD',
  //   description: 'Passionate trader and investor',
  //   achievements: ['Top Trader', '1000 Trades', '5 Star Rating'],
  //   isTrader: true,
  //   biography:
  //     "I've been trading for over 10 years, specializing in forex and cryptocurrencies.",
  //   performance: [
  //     { date: '2023-01', value: 1000 },
  //     { date: '2023-02', value: 1200 },
  //     { date: '2023-03', value: 900 },
  //     { date: '2023-04', value: 1500 },
  //     { date: '2023-05', value: 1800 },
  //     { date: '2023-06', value: 1600 },
  //   ],
  // };
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (user.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(ProfileBox, {
                onUpdate: getProfile,
                user: user.value
              }, null, 8, ["user"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(PerformanceChart, {
                performance: user.value.performance,
                user: user.value.value
              }, null, 8, ["performance", "user"]),
              _createVNode(CommentsSection, { profile: user.value }, null, 8, ["profile"])
            ])
          ]))
        : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
    ]),
    _createVNode(_unref(Toaster))
  ], 64))
}
}

})