<template>
  <div class="min-h-screen bg-card border text-gray-100 p-4">
    <div class="container mx-auto">
      <h1 class="text-3xl font-bold mb-6">Escolha um Trader</h1>
      
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <div v-for="trader in traders" :key="trader.id" class="bg-gray-800 rounded-lg shadow-lg overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105" @click="openProfile(trader.id)">
          <img :src="trader.photo" :alt="trader.name" class="w-full h-48 object-cover">
          <div class="p-4">
            <h2 class="text-xl font-semibold mb-2">{{ trader.name }}</h2>
            <p class="text-gray-400 text-sm mb-4">{{ trader.description }}</p>
            <div class="flex justify-between items-center">
              <div class="flex items-center space-x-2">
                <ThumbsUpIcon class="w-5 h-5 text-green-500" />
                <span>{{ trader.likes }}</span>
              </div>
              <div class="flex items-center space-x-2">
                <ThumbsDownIcon class="w-5 h-5 text-red-500" />
                <span>{{ trader.unlikes }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { ThumbsUpIcon, ThumbsDownIcon } from 'lucide-vue-next'

const router = useRouter()
const traders = ref([
  {
    id: 1,
    name: "Ana Silva",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Especialista em análise técnica com 7 anos de experiência em mercados de ações.",
    likes: 342,
    unlikes: 12
  },
  {
    id: 2,
    name: "Carlos Mendes",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Day trader focado em criptomoedas, com histórico comprovado de retornos acima da média.",
    likes: 289,
    unlikes: 8
  },
  {
    id: 3,
    name: "Luciana Ferreira",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Especialista em mercados futuros e opções, com MBA em Finanças pela USP.",
    likes: 421,
    unlikes: 15
  },
  {
    id: 4,
    name: "Ricardo Oliveira",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Trader quantitativo com background em Ciência da Computação e Estatística.",
    likes: 376,
    unlikes: 7
  },
  {
    id: 5,
    name: "Fernanda Costa",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Especialista em análise fundamentalista e estratégias de longo prazo.",
    likes: 298,
    unlikes: 5
  },
  {
    id: 6,
    name: "Marcelo Santos",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Trader de Forex com 10 anos de experiência em mercados internacionais.",
    likes: 512,
    unlikes: 18
  },
  {
    id: 7,
    name: "Patrícia Lima",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Especialista em gestão de risco e estratégias de hedge para portfolios diversificados.",
    likes: 387,
    unlikes: 9
  },
  {
    id: 8,
    name: "Eduardo Martins",
    photo: "/placeholder.svg?height=192&width=256",
    description: "Trader algorítmico com foco em desenvolvimento de sistemas automatizados de alta frequência.",
    likes: 456,
    unlikes: 11
  }
])

function openProfile(id) {
  router.push(`/profile/${id}`)
}
</script>