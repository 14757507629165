<template>
  <div class="bg-gradient-to-br text-white">
    <main class="container mx-auto px-4 py-8" v-if="tournaments">
      <div class="flex flex-col md:flex-row justify-between items-center mb-8">
        <h2 class="text-3xl font-bold mb-4 md:mb-0 bg-clip-text">Torneios disponíveis</h2>
      </div>

      <TransitionGroup
        name="tournament-list"
        tag="div"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        <div
          v-for="tournament in tournaments.data"
          :key="tournament.id"
          class="bg-card rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-emerald-500/20 hover:scale-105 cursor-pointer"
          @click="openTournamentDetails(tournament)"
        >
          <RouterLink :to="{ name: 'tournaments.show', params: { tournamentId: tournament.id } }">
            <div class="p-6">
              <div class="flex justify-between items-start mb-4">
                <h3 class="text-xl font-semibold">{{ tournament.name }}</h3>
                <span
                  :class="{
                    'bg-emerald-500': tournament.status === TournamentStatusEnum.FINISHED,
                    'bg-blue-500': tournament.status === TournamentStatusEnum.OPEN,
                    'bg-red-500': tournament.status === TournamentStatusEnum.CLOSED
                  }"
                  class="px-2 py-1 rounded-full text-xs font-semibold"
                >
                  {{ tournamentStatusLabels[tournament.status] }}
                </span>
              </div>
              <p class="text-gray-400 mb-4">{{ tournament.description }}</p>
              <div class="flex items-center justify-between text-sm text-gray-400">
                <div v-if="tournament.prizes.length" class="flex items-center">
                  <TrophyIcon class="mr-2 h-4 w-4 text-yellow-500" />
                  <template v-if="tournament.prizes[0].type === TournamentPrizeTypeEnum.PIX">{{ tournament.prizes[0].amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' }) }}</template>
                  <template v-else>{{ tournament.prizes[0].description }}</template>
                </div>
                <div class="flex items-center">
                  <UsersIcon class="mr-2 h-4 w-4 text-blue-500" />
                  {{ tournament.participantCount }} participantes
                </div>
              </div>
            </div>
            <div class="bg-zinc-700 px-6 py-4">
              <div class="flex items-center justify-between text-sm">
                <div class="flex items-center">
                  <CalendarIcon class="mr-2 h-4 w-4 text-emerald-500" />
                  {{ formatDate(tournament.startAt) }} - {{ formatDate(tournament.endAt) }}
                </div>
                <ArrowRightIcon class="h-4 w-4 text-emerald-500" />
              </div>
            </div>
          </RouterLink>
        </div>
      </TransitionGroup>
    </main>
    <SpinnerLoader v-else />

    <!-- <TournamentDetailsModal
      v-if="selectedTournament"
      :tournament="selectedTournament"
      @close="selectedTournament = null"
    /> -->
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { format } from 'date-fns'
import {
  Search as SearchIcon,
  Filter as FilterIcon,
  ChevronDown as ChevronDownIcon,
  Trophy as TrophyIcon,
  Users as UsersIcon,
  Calendar as CalendarIcon,
  ArrowRight as ArrowRightIcon,
  ChartLine as ChartLineIcon
} from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios';
import { Tournament } from '@/interfaces/tournaments/Tournament';
import { Pagination } from '@/interfaces/Pagination';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { TournamentStatusEnum, tournamentStatusLabels } from '@/enums/tournaments/TournamentStatusEnum';
import { TournamentPrizeTypeEnum } from '@/enums/tournaments/prizes/TournamentPrizeTypeEnum';

const selectedTournament = ref<Tournament>()
const axios = useAxios()
const tournaments = ref<Pagination<Tournament>>()

const formatDate = (date: Date) => {
  return format(date, "MMM d, yyyy")
}

const openTournamentDetails = (tournament: Tournament) => {
  selectedTournament.value = tournament
}

function getTournaments(page = 1) {
  axios.get('/tournaments', { params: { page } })
    .then((res) => {
      tournaments.value = res.data
    })
}

onMounted(() => {
  getTournaments()
})
</script>

<style scoped>
.tournament-list-enter-active,
.tournament-list-leave-active {
  transition: all 0.5s ease;
}
.tournament-list-enter-from,
.tournament-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>