<template>
  <div class="flex min-h-screen w-full flex-col bg-background">
    <aside class="fixed top-10 left-8 mt-7 h-full flex-col pt-4 hidden md:flex w-30 text-center z-10">
      <!-- <div class="p-2">
        <Button variant="outline" size="icon" aria-label="Home">
          <Triangle class="size-8 fill-foreground" />
        </Button>
      </div> -->
      <nav class="grid gap-6 p-2 pt-0 rounded-lg bg-background">
        <!-- <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'home' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Playground"
                >
                  <Home class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Home
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> -->
        <TooltipProvider v-if="hasRoles([AclEnum.PRODUCER, AclEnum.ADMIN])">
          <Tooltip :delay-duration="10">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'courses.index' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Cursos"
                  exact-active-class="bg-muted"
                >
                  <Book class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Cursos
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <!-- <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'courses.index' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Loja"
                >
                  <StoreIcon class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Loja
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> -->
        <!-- <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <Button
                variant="ghost"
                size="icon"
                class="rounded-lg"
                aria-label="Meus certificados"
              >
                <Award class="size-7" :stroke-width="1.25" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Meus certificados
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> -->
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'courses.my' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Meus cursos"
                >
                  <LibraryBig class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Meus cursos
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'feed' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Comunidade"
                >
                  <Users class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Comunidade
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'chat' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Ranking"
                >
                  <MessageSquare class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Chat
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'affiliates' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Ranking"
                >
                  <Waypoints class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Afiliados
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'traders' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Ranking"
                >
                  <Handshake class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Traders
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: 'ranking' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Ranking"
                >
                  <Medal class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Ranking
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip :delay-duration="100">
            <TooltipTrigger as-child>
              <RouterLink :to="{ name: hasRoles([AclEnum.PRODUCER, AclEnum.ADMIN]) ? 'tournaments.manage' : 'tournaments' }" class="rounded-lg" exact-active-class="bg-muted">
                <Button
                  variant="ghost"
                  size="icon"
                  class="rounded-lg"
                  aria-label="Torneios"
                >
                  <Trophy class="size-7" :stroke-width="1.25" />
                </Button>
              </RouterLink>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Torneios
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <!-- <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <Button
                variant="ghost"
                size="icon"
                class="mt-auto rounded-lg"
                aria-label="Account"
              >
                <Users class="size-7" :stroke-width="1.25" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Comunidade
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> -->
      </nav>
      <nav class="mt-auto grid gap-1 p-2">
        <!-- <TooltipProvider>
          <Tooltip>
            <TooltipTrigger as-child>
              <Button
                variant="ghost"
                size="icon"
                class="mt-auto rounded-lg"
                aria-label="Help"
              >
                <LifeBuoy class="size-7" :stroke-width="1.25" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right" :side-offset="5">
              Help
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> -->
      </nav>
    </aside>

    <header class="bg-background sticky top-0 flex h-16 items-center gap-4 border-b mb-3 px-4 md:px-7 z-50">
      <nav class="fixed ml-7 hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6 left-5">
        <a
          href="/#"
          class="flex items-center gap-2 text-lg font-semibold md:text-base"
        >
          <img v-if="tenantLogo" :src="tenantLogo.bucketLocation" height="28" width="28" />
        </a>
        <a
          href="/#"
          class="text-foreground transition-colors hover:text-foreground"
        >
          
        </a>
      </nav>
      <Sheet>
        <SheetTrigger as-child>
          <Button
            variant="outline"
            size="icon"
            class="shrink-0 md:hidden"
          >
            <Menu class="h-5 w-5" />
            <span class="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav class="grid gap-4 text-lg font-medium">
            <a
              href="#"
              class="flex items-center gap-2 text-lg font-semibold px-3"
            >
              <Package2 class="h-6 w-6" />
              <!-- <span class="sr-only">Acme Inc</span> -->
            </a>
            <RouterLink :to="{ name: 'courses.index' }" class="hover:text-foreground px-3 py-2 rounded-lg" exact-active-class="bg-muted">
              Cursos
            </RouterLink>
            <RouterLink :to="{ name: 'courses.my' }" class="hover:text-foreground px-3 py-2 rounded-lg" exact-active-class="bg-muted">
              Meus cursos
            </RouterLink>
          </nav>
        </SheetContent>
      </Sheet>
      <div class="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <form class="ml-auto flex-1 sm:flex-initial"></form>
        <DropdownMenu v-model:open="isNotificationDropdownOpen" @update:open="isNotificationDropdownOpen = $event">
          <DropdownMenuTrigger as-child>
            <Button variant="secondary" size="icon" class="rounded-full relative">
              <Bell class="h-5 w-5" />
              <span v-if="unreadCount && unreadCount > 0" class="absolute -top-1 -right-1 h-5 w-5 rounded-full bg-red-500 text-xs text-white flex items-center justify-center">
                {{ unreadCount }}
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="w-80" align="end">
            <NotificationCard @show-more="() => { $router.push({ name: 'notifications' }); isNotificationDropdownOpen = false }" :has-scroll-area="true" />
          </DropdownMenuContent>
        </DropdownMenu>
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="secondary" size="icon" class="rounded-full">
              <CircleUser class="h-5 w-5" />
              <span class="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem @click="$router.push({ name: 'profile', params: { profileId: userAuthenticated.userId }  })">
              <UserCog class="h-4 w-4 mr-2" /> 
              Minha Conta</DropdownMenuItem>
            <DropdownMenuSeparator />
            <!-- <DropdownMenuItem>
              <HelpCircle class="h-4 w-4 mr-2" /> Suporte
            </DropdownMenuItem> -->
            <DropdownMenuItem @click="$router.push({ name: 'terms-of-use' })">
              <HelpCircle class="h-4 w-4 mr-2" /> Regras e Contrato
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem 
            class="cursor-pointer"
            @click="logout(
              () => $router.push({ name: 'login' })
            )">
              <LogOut class="h-4 w-4 mr-2" /> Sair
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <!-- <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="outline">
              <Icon icon="radix-icons:moon" class="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              <Icon icon="radix-icons:sun" class="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
              <span class="sr-only">Mudar tema</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem @click="mode = 'light'">
              Claro
            </DropdownMenuItem>
            <DropdownMenuItem @click="mode = 'dark'">
              Escuro
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> -->
      </div>
    </header>

    <div class="md:ml-[100px] flex justify-center px-7">
      <RouterView class="max-w-[1800px] w-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Package2,
  Users,
  CircleUser, 
  Menu, 
  LogOut,
  HelpCircle,
  Book,
  LibraryBig,
  Trophy,
  UserCog,
  MessageSquare,
  Waypoints,
  Handshake,
  Bell,
  Medal
} from 'lucide-vue-next'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { AclEnum } from '@/enums/acl/AclEnum'
import { Button } from '@/components/ui/button'

import TooltipProvider from '@/components/ui/tooltip/TooltipProvider.vue'
import Tooltip from '@/components/ui/tooltip/Tooltip.vue'
import TooltipTrigger from '@/components/ui/tooltip/TooltipTrigger.vue'
import TooltipContent from '@/components/ui/tooltip/TooltipContent.vue'
import { useAuth } from '@/composables/useAuth'
import { computed, onMounted, ref } from 'vue'
import { useTenant } from '@/composables/useTenant'
import { useNotification } from '@/composables/useNotification'
import NotificationCard from '@/views/notifications/NotificationCard.vue'
import { NotificationStatusEnum } from '@/enums/notifications/NotificationStatusEnum'

const isNotificationDropdownOpen = ref(false)
const { tenantLogo, initializeTenant } = useTenant()

const unreadCount = computed(() => notifications.value?.data.filter(n => n.status === NotificationStatusEnum.UNREAD).length)

const { logout, userAuthenticated, hasRoles } = useAuth()
const { notifications, initializeNotifications, readAllNotifications } = useNotification()

onMounted(() => {
  initializeTenant()
  initializeNotifications()
})
</script>