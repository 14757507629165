import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = { class: "text-gray-100 mb-4 whitespace-pre-wrap" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center space-x-2"
}
const _hoisted_5 = { class: "flex -space-x-1" }
const _hoisted_6 = {
  key: 1,
  class: "flex space-x-4"
}
const _hoisted_7 = { class: "flex justify-between w-full" }
const _hoisted_8 = { class: "flex space-x-2" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "flex gap-2 items-center" }

import { ref } from 'vue'
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '@/components/ui/card'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { 
  DropdownMenu, 
  DropdownMenuTrigger, 
  DropdownMenuContent, 
  DropdownMenuItem, 
} from '@/components/ui/dropdown-menu'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
// import { Collapse } from '@/components/ui/collapse'
import { 
  ThumbsUpIcon, 
  MessageCircleIcon, 
  ShareIcon, 
  MoreHorizontalIcon,
  ClockIcon,
  Trash2,
  AlertTriangle,
  Loader2
} from 'lucide-vue-next'
import { Separator } from 'radix-vue'
import TooltipProvider from '@/components/ui/tooltip/TooltipProvider.vue'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog'
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue'
import DialogDescription from '@/components/ui/dialog/DialogDescription.vue'
import { useAxios } from '@/composables/useAxios'
import { toast } from '@/components/ui/toast'
import { useDate } from '@/composables/useDate'
import { computed } from 'vue'
import { ProfileTopic } from '@/interfaces/profiles/profileTopics/ProfileTopic'
import { Profile } from '@/interfaces/profiles/Profile'
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum'
import { ProfileTopicReactionContentEnum, profileTopicReactionIcons, profileTopicReactionLabels } from '@/enums/profiles/profileTopics/reactions/ProfileTopicReactionContentEnum'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileTopicComponent',
  props: {
    topic: {},
    profile: {}
  },
  emits: ['delete'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const props = __props
const userAuthenticated = JSON.parse(localStorage.getItem('authUser') ?? '')
const profileAvatar = computed(() =>
  props.profile.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const isAuthenticatedUserProfile = userAuthenticated.userId == props.profile.userId
const { timeAgo } = useDate()
const isConfirmDeleteModalOpen = ref(false)
const isDeletingTopic = ref(false)
const axios = useAxios()
const alreadyReacted = computed(() => props.topic.reactions.find((r) => r.userId === userAuthenticated.userId))
const distinctReactions = computed(() => {
  return Array.from(new Set(props.topic.reactions.map(reaction => reaction.content))) as ProfileTopicReactionContentEnum[];
})

function deleteTopic() {
  isDeletingTopic.value = true

  axios.delete(`/profile/${props.topic.profileId}/topics/${props.topic.id}`)
    .then(() => {
      toast({
        title: 'Publicação excluida com sucesso',
        class: 'bg-green-500 text-white'
      })
      isConfirmDeleteModalOpen.value = false
      emit('delete')
    })
    .finally(() => isDeletingTopic.value = false)
}

const toggleReaction = async (content: ProfileTopicReactionContentEnum) => {
  axios.post(`/profile/${props.topic.profileId}/topics/${props.topic.id}/reactions`, { content })

  if (!alreadyReacted.value) {
    props.topic.reactions.push({
      profileTopicId: props.topic.id,
      content,
      userId: userAuthenticated.userId
    })
  } else {
    props.topic.reactions = props.topic.reactions.filter((r) => r.userId !== userAuthenticated.userId)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_unref(Card), { class: "overflow-hidden transition-shadow duration-300 hover:shadow-lg" }, {
    default: _withCtx(() => [
      _createVNode(_unref(CardHeader), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_RouterLink, {
                to: { name: 'profile', params: { profileId: _ctx.profile.userId } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Avatar), null, {
                    default: _withCtx(() => [
                      (profileAvatar.value)
                        ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                            key: 0,
                            src: profileAvatar.value.bucketLocation,
                            alt: _ctx.profile.name
                          }, null, 8, ["src", "alt"]))
                        : _createCommentVNode("", true),
                      _createVNode(_unref(AvatarFallback), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.profile.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"]),
              _createElementVNode("div", null, [
                _createVNode(_component_RouterLink, {
                  to: { name: 'profile', params: { profileId: _ctx.profile.userId } }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CardTitle), { class: "text-lg font-semibold text-gray-100" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.profile.name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_unref(CardDescription), { class: "text-sm text-gray-400 flex items-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(ClockIcon), { class: "h-3 w-3 mr-1" }),
                    _createTextVNode(" " + _toDisplayString(_unref(timeAgo)(_ctx.topic.createdAt)), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            (_ctx.profile.userId == _unref(userAuthenticated).userId)
              ? (_openBlock(), _createBlock(_unref(DropdownMenu), { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DropdownMenuTrigger), { asChild: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          size: "icon"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(MoreHorizontalIcon), {
                              class: "h-5 w-5",
                              "stroke-width": 1.25
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(DropdownMenuContent), { class: "w-56" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(DropdownMenuItem), {
                          class: "text-red-500",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (isConfirmDeleteModalOpen.value = true))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Trash2), {
                              class: "mr-2 h-4 w-4",
                              "stroke-width": 1.25
                            }),
                            _cache[5] || (_cache[5] = _createElementVNode("span", null, "Excluír", -1))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(CardContent), null, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.topic.content), 1)
        ]),
        _: 1
      }),
      _createVNode(_unref(CardFooter), { class: "flex flex-col space-y-4" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center w-full text-gray-400 text-sm", { 'justify-between': (_ctx.topic.reactions.length > 0), 'justify-end': !_ctx.topic.reactions.length }])
          }, [
            (_ctx.topic.reactions.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(distinctReactions.value, (reaction, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "rounded-full bg-blue-500 w-5 h-5 flex items-center justify-center text-xs text-white"
                      }, _toDisplayString(reaction), 1))
                    }), 128))
                  ]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.topic.reactions.length), 1)
                ]))
              : _createCommentVNode("", true),
            (!!_ctx.topic.postsCount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.topic.postsCount) + " comentários", 1)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createVNode(_unref(Separator), { class: "bg-gray-700" }),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(TooltipProvider, null, {
              default: _withCtx(() => [
                _createVNode(_unref(Tooltip), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TooltipTrigger), { asChild: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          variant: "ghost",
                          class: _normalizeClass(["flex-1", { 
                'text-blue-500 hover:text-blue-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LIKE,
                'text-red-500 hover:text-red-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LOVE,
              }]),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleReaction(_unref(ProfileTopicReactionContentEnum).LIKE)))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(alreadyReacted.value ? _unref(profileTopicReactionIcons)[alreadyReacted.value.content] : _unref(ThumbsUpIcon)), {
                              "stroke-width": 1.25,
                              class: _normalizeClass(["h-5 w-5 mr-2 transition-colors duration-200", { 
                    'text-blue-500 fill-blue-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LIKE,
                    'text-red-500 fill-red-500': alreadyReacted.value?.content === _unref(ProfileTopicReactionContentEnum).LOVE,
                  }])
                            }, null, 8, ["class"])),
                            _createTextVNode(" " + _toDisplayString(alreadyReacted.value ? _unref(profileTopicReactionLabels)[alreadyReacted.value?.content] : 'Curtir'), 1)
                          ]),
                          _: 1
                        }, 8, ["class"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(TooltipContent), { class: "bg-gray-800 border-gray-700" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ProfileTopicReactionContentEnum), (reaction) => {
                            return (_openBlock(), _createElementBlock("button", {
                              onClick: ($event: any) => (toggleReaction(reaction)),
                              key: reaction,
                              class: "cursor-pointer text-xl hover:scale-125 transition-transform duration-200"
                            }, _toDisplayString(reaction), 9, _hoisted_9))
                          }), 128))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(Button), {
              variant: "ghost",
              class: "flex-1",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'profile.topics.show', params: { profileId: _ctx.profile.id, profileTopicId: _ctx.topic.id } })))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(MessageCircleIcon), {
                  class: "h-5 w-5 mr-2",
                  "stroke-width": 1.25
                }),
                _cache[6] || (_cache[6] = _createTextVNode(" Comentar "))
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_unref(Dialog), {
        open: isConfirmDeleteModalOpen.value,
        "onUpdate:open": _cache[4] || (_cache[4] = (open) => isConfirmDeleteModalOpen.value = open)
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
            default: _withCtx(() => [
              _createVNode(_unref(DialogHeader), null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                    _createVNode(DialogTitle, { class: "text-2xl" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Excluir Publicação")
                      ])),
                      _: 1
                    })
                  ]),
                  _createVNode(DialogDescription, { class: "py-2" }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Você está prestes a excluir esta publicação. Essa ação é irreversível e todos os dados relacionados a este publicação serão permanentemente removidos. ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
              _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogClose), { "as-child": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Button), {
                        type: "button",
                        variant: "secondary"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Cancelar ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(Button), {
                    type: "button",
                    disabled: isDeletingTopic.value,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (deleteTopic()))
                  }, {
                    default: _withCtx(() => [
                      (isDeletingTopic.value)
                        ? (_openBlock(), _createBlock(_unref(Loader2), {
                            key: 0,
                            class: "w-4 h-4 mr-2 animate-spin"
                          }))
                        : _createCommentVNode("", true),
                      _cache[10] || (_cache[10] = _createTextVNode(" Excluir Publicação "))
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["open"])
    ]),
    _: 1
  }))
}
}

})