import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "min-h-screen text-gray-100 p-8" }
const _hoisted_2 = { class: "mt-8 max-w-7xl mx-auto bg-card rounded-lg shadow-xl overflow-hidden" }
const _hoisted_3 = { class: "p-6" }
const _hoisted_4 = { class: "flex flex-wrap items-center justify-between gap-4 mb-6" }
const _hoisted_5 = { class: "flex items-center gap-2" }
const _hoisted_6 = { class: "flex items-center gap-3" }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { class: "text-sm text-gray-400" }
const _hoisted_9 = { class: "flex items-center gap-2" }
const _hoisted_10 = { class: "font-mono" }

import { ref, computed, onMounted } from 'vue'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
// import { Progress } from '@/components/ui/progress'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { TrendingUpIcon, TrendingDownIcon, ShieldIcon } from 'lucide-vue-next'
import TopThree from './components/TopThree.vue'
import { useRanking } from '@/composables/useRanking'


export default /*@__PURE__*/_defineComponent({
  __name: 'RankingView',
  setup(__props) {

const { initializeRankings } = useRanking()
// Simulated data
const members = ref([
  { id: 1, name: 'Alice Johnson', initials: 'AJ', avatar: '/placeholder.svg?height=40&width=40', title: 'Desenvolvedor Sênior', level: 32, points: 15780, progress: 78, trend: 'up' },
  { id: 2, name: 'Bob Smith', initials: 'BS', avatar: '/placeholder.svg?height=40&width=40', title: 'Designer UX', level: 28, points: 14200, progress: 65, trend: 'down' },
  { id: 3, name: 'Carol Williams', initials: 'CW', avatar: '/placeholder.svg?height=40&width=40', title: 'Gerente de Projeto', level: 25, points: 12500, progress: 92, trend: 'up' },
  // Add more members here...
])

const searchQuery = ref('')
const selectedCategory = ref('overall')

const filteredMembers = computed(() => {
  return members.value
    .filter(member => member.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
    .sort((a, b) => b.points - a.points)
})

const totalMembers = computed(() => members.value.length)
const averagePoints = computed(() => {
  const total = members.value.reduce((sum, member) => sum + member.points, 0)
  return Math.round(total / members.value.length)
})
const topLevelMember = computed(() => {
  return members.value.reduce((top, member) => member.level > top.level ? member : top, members.value[0])
})

onMounted(() => {
  initializeRankings()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TopThree),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(Input), {
            placeholder: "Pesquisar membro...",
            class: "max-w-xs",
            modelValue: searchQuery.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createVNode(_unref(Table), null, {
          default: _withCtx(() => [
            _createVNode(_unref(TableHeader), null, {
              default: _withCtx(() => [
                _createVNode(_unref(TableRow), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TableHead), { class: "w-[100px]" }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Posição")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(TableHead), null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Membro")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(TableHead), null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Patente")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(TableHead), null, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Pontos")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TableBody), null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredMembers.value, (member, index) => {
                  return (_openBlock(), _createBlock(_unref(TableRow), {
                    key: member.id,
                    class: _normalizeClass([{'bg-yellow-900/20': index === 0, 'bg-gray-400/10': index === 1, 'bg-orange-900/20': index === 2}, "transition-all hover:bg-gray-700/50"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TableCell), { class: "font-medium" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", {
                              class: _normalizeClass([
                      'w-8 h-8 rounded-full flex items-center justify-center text-xs font-bold',
                      index === 0 ? 'bg-yellow-500 text-yellow-900' :
                      index === 1 ? 'bg-gray-300 text-gray-800' :
                      index === 2 ? 'bg-orange-500 text-orange-900' :
                      'bg-gray-700'
                    ])
                            }, _toDisplayString(index + 1), 3),
                            (member.trend === 'up')
                              ? (_openBlock(), _createBlock(_unref(TrendingUpIcon), {
                                  key: 0,
                                  class: "text-green-500"
                                }))
                              : (member.trend === 'down')
                                ? (_openBlock(), _createBlock(_unref(TrendingDownIcon), {
                                    key: 1,
                                    class: "text-red-500"
                                  }))
                                : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_unref(TableCell), null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_unref(Avatar), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(AvatarImage), {
                                  src: member.avatar,
                                  alt: member.name
                                }, null, 8, ["src", "alt"]),
                                _createVNode(_unref(AvatarFallback), null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(member.initials), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_7, _toDisplayString(member.name), 1),
                              _createElementVNode("div", _hoisted_8, _toDisplayString(member.title), 1)
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_unref(TableCell), null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_unref(ShieldIcon), {
                              class: _normalizeClass([
                    'w-5 h-5',
                    member.level < 10 ? 'text-gray-500' :
                    member.level < 20 ? 'text-blue-500' :
                    member.level < 30 ? 'text-purple-500' :
                    'text-yellow-500'
                  ])
                            }, null, 8, ["class"]),
                            _createTextVNode(" " + _toDisplayString(member.level), 1)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_unref(TableCell), null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_10, _toDisplayString(member.points.toLocaleString()), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["class"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})