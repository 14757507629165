<template>
    <div class="min-h-screen p-8">
      <div class="max-w-7xl mx-auto">
        <h1 class="text-3xl font-bold mb-8">Gerenciamento de Afiliados</h1>
        
        <!-- Widget de Desempenho Geral -->
        <div class="bg-card border rounded-lg shadow-md p-6 mb-8">
          <h2 class="text-xl font-semibold mb-4">Desempenho Geral</h2>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div class="bg-blue-100 p-4 rounded-lg">
              <p class="text-sm text-blue-600">Total de Cliques</p>
              <p class="text-2xl font-bold">{{ totalClicks }}</p>
            </div>
            <div class="bg-green-100 p-4 rounded-lg">
              <p class="text-sm text-green-600">Comissões Totais</p>
              <p class="text-2xl font-bold">R$ {{ totalCommissions.toFixed(2) }}</p>
            </div>
            <div class="bg-purple-100 p-4 rounded-lg">
              <p class="text-sm text-purple-600">Taxa de Conversão</p>
              <p class="text-2xl font-bold">{{ conversionRate.toFixed(2) }}%</p>
            </div>
          </div>
        </div>
  
        <!-- Gráfico de Estatísticas -->
        <div class="bg-card border rounded-lg shadow-md p-6 mb-8">
          <h2 class="text-xl font-semibold mb-4">Estatísticas de Cliques e Comissões</h2>
          <div class="w-full h-64">
            <!-- <LineChart :chart-data="chartData" :options="chartOptions" /> -->
          </div>
        </div>
  
        <!-- Lista de Links de Afiliados -->
        <div class="bg-card border rounded-lg shadow-md p-6 mb-8">
          <h2 class="text-xl font-semibold mb-4">Seus Links de Afiliados</h2>
          <ul class="space-y-4">
            <li v-for="link in affiliateLinks" :key="link.id" class="flex items-center justify-between bg-gray-50 p-4 rounded-lg">
              <div>
                <p class="font-medium">{{ link.name }}</p>
                <p class="text-sm text-gray-500">{{ link.url }}</p>
              </div>
              <div class="flex space-x-2">
                <button @click="editLink(link)" class="text-blue-600 hover:text-blue-800">
                  <PencilIcon class="h-5 w-5" />
                </button>
                <button @click="deleteLink(link.id)" class="text-red-600 hover:text-red-800">
                  <TrashIcon class="h-5 w-5" />
                </button>
              </div>
            </li>
          </ul>
        </div>
  
        <!-- Formulário para Adicionar/Editar Link -->
        <div class="bg-card border rounded-lg shadow-md p-6 mb-8">
          <h2 class="text-xl font-semibold mb-4">{{ isEditing ? 'Editar Link' : 'Adicionar Novo Link' }}</h2>
          <form @submit.prevent="submitLink" class="space-y-4">
            <div>
              <label for="linkName" class="block text-sm font-medium text-gray-700">Nome do Link</label>
              <input v-model="newLink.name" type="text" id="linkName" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
            </div>
            <div>
              <label for="linkUrl" class="block text-sm font-medium text-gray-700">URL do Produto</label>
              <input v-model="newLink.url" type="url" id="linkUrl" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
            </div>
            <button type="submit" class="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              {{ isEditing ? 'Atualizar Link' : 'Adicionar Link' }}
            </button>
          </form>
        </div>
  
        <!-- Tabela de Transações Recentes -->
        <div class="bg-card border rounded-lg shadow-md p-6">
          <h2 class="text-xl font-semibold mb-4">Indicações Recentes</h2>
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comissão</th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody class="bg-card border divide-y divide-gray-200">
              <tr v-for="transaction in recentTransactions" :key="transaction.id">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ transaction.date }}</td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">R$ {{ transaction.commission.toFixed(2) }}</td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <span :class="{
                    'px-2 inline-flex text-xs leading-5 font-semibold rounded-full': true,
                    'bg-green-100 text-green-800': transaction.status === 'Aprovado',
                    'bg-yellow-100 text-yellow-800': transaction.status === 'Pendente',
                    'bg-red-100 text-red-800': transaction.status === 'Rejeitado'
                  }">
                    {{ transaction.status }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <!-- Notificações -->
      <div class="fixed bottom-4 right-4">
        <transition-group name="notification" tag="div" class="space-y-2">
          <div v-for="notification in notifications" :key="notification.id" class="bg-green-500 text-white p-4 rounded-lg shadow-lg">
            {{ notification.message }}
          </div>
        </transition-group>
      </div>
    </div>
  </template>
  
<script setup>
import { ref, computed, onMounted } from 'vue'
import { PencilIcon, TrashIcon } from 'lucide-vue-next'
// import { LineChart } from '@/components/ui/chart-line'

// Estado
const affiliateLinks = ref([
  { id: 1, name: 'Produto A', url: 'https://exemplo.com/produto-a' },
  { id: 2, name: 'Produto B', url: 'https://exemplo.com/produto-b' },
])
const newLink = ref({ name: '', url: '' })
const isEditing = ref(false)
const editingId = ref(null)
const customUrl = ref('')
const generatedLink = ref('')
const notifications = ref([])

// Dados simulados
const totalClicks = ref(1234)
const totalCommissions = ref(5678.90)
const conversionRate = ref(3.45)
const recentTransactions = ref([
  { id: 1, date: '2023-05-01', product: 'Produto A', commission: 50.00, status: 'Aprovado' },
  { id: 2, date: '2023-05-02', product: 'Produto B', commission: 30.00, status: 'Pendente' },
  { id: 3, date: '2023-05-03', product: 'Produto C', commission: 40.00, status: 'Rejeitado' },
])

// Dados do gráfico
const chartData = computed(() => ({
  labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
  datasets: [
    {
      label: 'Cliques',
      borderColor: '#3B82F6',
      data: [120, 190, 300, 250, 400, 350],
    },
    {
      label: 'Comissões (R$)',
      borderColor: '#10B981',
      data: [500, 800, 1200, 1000, 1500, 1300],
    }
  ]
}))

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
}

// Métodos
const submitLink = () => {
  if (isEditing.value) {
    const index = affiliateLinks.value.findIndex(link => link.id === editingId.value)
    if (index !== -1) {
      affiliateLinks.value[index] = { ...affiliateLinks.value[index], ...newLink.value }
    }
    isEditing.value = false
    editingId.value = null
  } else {
    affiliateLinks.value.push({ id: Date.now(), ...newLink.value })
  }
  newLink.value = { name: '', url: '' }
}

const editLink = (link) => {
  newLink.value = { ...link }
  isEditing.value = true
  editingId.value = link.id
}

const deleteLink = (id) => {
  affiliateLinks.value = affiliateLinks.value.filter(link => link.id !== id)
}

const generateCustomLink = () => {
  if (customUrl.value) {
    const affiliateId = 'ABC123' // Simulação de ID de afiliado
    generatedLink.value = `${customUrl.value}?ref=${affiliateId}`
    addNotification('Link personalizado gerado com sucesso!')
  }
}

const addNotification = (message) => {
  const id = Date.now()
  notifications.value.push({ id, message })
  setTimeout(() => {
    notifications.value = notifications.value.filter(n => n.id !== id)
  }, 3000)
}

onMounted(() => {
  // Aqui você pode adicionar qualquer lógica de inicialização necessária
})
</script>

<style scoped>
.notification-enter-active,
.notification-leave-active {
  transition: all 0.5s ease;
}
.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>