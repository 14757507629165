import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "w-full max-xl mx-auto" }
const _hoisted_2 = { class: "grid grid-cols-12 gap-6" }
const _hoisted_3 = { class: "col-span-6 space-y-4" }
const _hoisted_4 = { class: "col-span-6 space-y-4" }
const _hoisted_5 = { class: "grid gap-4 py-4" }

import { ref } from 'vue'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Calendar } from '@/components/ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { Loader2, CalendarIcon, PlusCircle, XCircle, Trophy } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { TournamentStatusEnum, tournamentStatusLabels } from "@/enums/tournaments/TournamentStatusEnum"
import { format } from 'date-fns'
import { useForm } from 'vee-validate'
import { ptBR } from 'date-fns/locale'
import { TournamentPrizeTypeEnum, tournamentPrizeTypeEnumLabels } from '@/enums/tournaments/prizes/TournamentPrizeTypeEnum'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateTournamentView',
  setup(__props) {

function formatDate(date: Date | null) {
  return date ? format(date, 'dd/MM/yyyy', { locale: ptBR }) : 'Selecione uma data'
}

const isCreatingTournament = ref(false)
const isPrizeFormOpen = ref(false)
const axios = useAxios()

const newPrize = ref<{ type: string, amount?: number, position?: number, description: string }>({
  type: '',
  amount: undefined,
  position: undefined,
  description: ''
})

const formSchema = z.object({
  name: z.string({ required_error: "Este campo é obrigatório" }).min(3, "O nome deve ter pelo menos 3 caracteres"),
  description: z.string().optional(),
  status: z.nativeEnum(TournamentStatusEnum, { required_error: "Este campo é obrigatório" }),
  startAt: z.date({ required_error: "Este campo é obrigatório" }),
  endAt: z.date({ required_error: "Este campo é obrigatório" }),
  prizes: z.array(
    z.object({
      type: z.enum(Object.values(TournamentPrizeTypeEnum) as [string, ...string[]]),
      amount: z.number().optional(),
      position: z.number().int(),
      description: z.string().optional(),
    })
  ).default([])
}).refine((data) => data.endAt > data.startAt, {
  message: "A data de término deve ser posterior à data de início",
  path: ["endAt"],
});

const { handleSubmit, resetForm, setFieldValue, values } = useForm({
  validationSchema: toTypedSchema(formSchema),
})

const onSubmit = handleSubmit((values) => {
  createTournament(values)
})

function createTournament(payload: z.infer<typeof formSchema>) {
  isCreatingTournament.value = true
  axios.post(`/tournaments`, {
    ...payload,
    startAt: format(payload.startAt, 'yyyy-MM-dd'),
    endAt: format(payload.endAt, 'yyyy-MM-dd'),
  })
    .then(() => {
      toast({
        title: 'Torneio criado com sucesso!',
        description: 'Seu novo torneio está pronto para uso.',
        class: 'bg-green-500 text-white'
      })
      resetForm()
    })
    .catch(() => {
      toast({
        title: 'Erro ao criar torneio',
        description: 'Verifique os dados e tente novamente',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingTournament.value = false)
}

function openPrizeForm() {
  isPrizeFormOpen.value = true
}

function addPrize() {
  const updatedPrizes = [
    ...(values.prizes ?? []),
    { ...newPrize.value, position: newPrize.value.position! }
  ].sort((a, b) => a.position - b.position);

  setFieldValue('prizes', updatedPrizes)
  newPrize.value = { type: '', amount: undefined, position: undefined, description: '' }
  isPrizeFormOpen.value = false
}

function removePrize(index: number) {
  setFieldValue('prizes', values.prizes?.filter((_, i) => i !== index) ?? [])
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)), ["prevent"])),
        class: "space-y-8"
      }, [
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "space-y-2 text-center" }, [
          _createElementVNode("h1", { class: "text-3xl font-bold tracking-tighter text-[#000] dark:text-white" }, "Novo Torneio"),
          _createElementVNode("p", { class: "text-sm text-[#666] dark:text-[#888]" }, "Crie um novo torneio para engajar participantes")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(FormField), { name: "name" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(FormItem), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Nome do Torneio")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(FormControl), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Input), _mergeProps({
                          type: "text",
                          placeholder: "Digite o nome do torneio"
                        }, field), null, 16)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_unref(FormMessage))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_unref(FormField), { name: "description" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(FormItem), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Descrição")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(FormControl), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Textarea), _mergeProps({ placeholder: "Digite uma breve descrição do torneio" }, field), null, 16)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_unref(FormMessage))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_unref(FormField), { name: "status" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(FormItem), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Status do Torneio")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(FormControl), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Select), _normalizeProps(_guardReactiveProps(field)), {
                          default: _withCtx(() => [
                            _createVNode(_unref(SelectTrigger), {
                              id: "status",
                              "aria-label": "Selecionar status"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(SelectValue), { placeholder: "Selecionar" })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(SelectContent), null, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(TournamentStatusEnum), (status) => {
                                  return (_openBlock(), _createBlock(_unref(SelectItem), {
                                    key: status,
                                    value: status
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(tournamentStatusLabels)[status]), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1040)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_unref(FormMessage))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_unref(FormField), { name: "startAt" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(FormItem), { class: "flex flex-col" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Data de Início")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(Popover), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(PopoverTrigger), { asChild: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(FormControl), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Button), {
                                  variant: "outline",
                                  class: _normalizeClass([
                        'w-full pl-3 text-left font-normal',
                        !field.value && 'text-muted-foreground'
                      ])
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(formatDate(field.value)) + " ", 1),
                                    _createVNode(_unref(CalendarIcon), { class: "ml-auto h-4 w-4 opacity-50" })
                                  ]),
                                  _: 2
                                }, 1032, ["class"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_unref(PopoverContent), {
                          class: "w-auto p-0",
                          align: "start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Calendar), {
                              mode: "single",
                              selected: field.value,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = (date) => _unref(setFieldValue)('startAt', new Date(date as string))),
                              "disabled-dates": { before: new Date() },
                              initialFocus: "",
                              locale: 'pt-BR'
                            }, null, 8, ["selected", "disabled-dates"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_unref(FormMessage))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_unref(FormField), { name: "endAt" }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(FormItem), { class: "flex flex-col" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Data de Término")
                      ])),
                      _: 1
                    }),
                    _createVNode(_unref(Popover), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(PopoverTrigger), { asChild: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(FormControl), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(Button), {
                                  variant: "outline",
                                  class: _normalizeClass([
                        'w-full pl-3 text-left font-normal',
                        !field.value && 'text-muted-foreground'
                      ])
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(formatDate(field.value)) + " ", 1),
                                    _createVNode(_unref(CalendarIcon), { class: "ml-auto h-4 w-4 opacity-50" })
                                  ]),
                                  _: 2
                                }, 1032, ["class"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_unref(PopoverContent), {
                          class: "w-auto p-0",
                          align: "start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Calendar), {
                              mode: "single",
                              selected: field.value,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = (date) => _unref(setFieldValue)('endAt', new Date(date as string))),
                              "disabled-dates": { before: new Date() },
                              initialFocus: "",
                              locale: 'pt-BR'
                            }, null, 8, ["selected", "disabled-dates"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_unref(FormMessage))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_unref(Button), {
              disabled: isCreatingTournament.value,
              type: "submit",
              class: "w-full text-white dark:text-[#000] font-medium py-2 px-4 rounded-md transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#000] dark:focus:ring-white focus:ring-opacity-50"
            }, {
              default: _withCtx(() => [
                (isCreatingTournament.value)
                  ? (_openBlock(), _createBlock(_unref(Loader2), {
                      key: 0,
                      class: "w-4 h-4 mr-2 animate-spin"
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(isCreatingTournament.value ? 'Criando...' : 'Adicionar Torneio'), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(Card), { class: "bg-card" }, {
              default: _withCtx(() => [
                _createVNode(_unref(CardHeader), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CardTitle), { class: "text-2xl font-semibold text-[#000] dark:text-white" }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("Prêmios do Torneio")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(CardContent), null, {
                  default: _withCtx(() => [
                    _createVNode(_TransitionGroup, {
                      name: "prize-list",
                      tag: "ul",
                      class: "space-y-2"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(values).prizes, (prize, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: "flex justify-between items-center p-2 bg-secondary rounded-md"
                          }, [
                            _createVNode(_unref(Trophy), {
                              class: "size-7 stroke-yellow-500",
                              "stroke-width": 1.25
                            }),
                            _createElementVNode("span", null, _toDisplayString(_unref(tournamentPrizeTypeEnumLabels)[prize.type as TournamentPrizeTypeEnum]) + " - " + _toDisplayString(prize.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' })) + " (" + _toDisplayString(prize.position) + "º lugar)", 1),
                            _createVNode(_unref(Button), {
                              variant: "ghost",
                              size: "icon",
                              onClick: _withModifiers(($event: any) => (removePrize(index)), ["prevent"])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(XCircle), { class: "h-4 w-4" })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(Button), {
                      onClick: _withModifiers(openPrizeForm, ["prevent"]),
                      class: "w-full mt-4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(PlusCircle), { class: "w-4 h-4 mr-2" }),
                        _cache[14] || (_cache[14] = _createTextVNode(" Adicionar Novo Prêmio "))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _createVNode(_unref(Dialog), {
      open: isPrizeFormOpen.value,
      "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => (isPrizeFormOpen.value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(DialogContent), { class: "sm:max-w-[425px]" }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogHeader), null, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogTitle), null, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createTextVNode("Adicionar Novo Prêmio")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(DialogDescription), null, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode("Preencha os detalhes do prêmio abaixo.")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("form", {
              onSubmit: _withModifiers(addPrize, ["prevent"])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(FormField), { name: "type" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode("Tipo de Prêmio")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(Select), {
                          modelValue: newPrize.value.type,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newPrize.value.type) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(SelectTrigger), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(SelectValue), { placeholder: "Selecione o tipo" })
                              ]),
                              _: 1
                            }),
                            _createVNode(_unref(SelectContent), null, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_unref(TournamentPrizeTypeEnum)), (type) => {
                                  return (_openBlock(), _createBlock(_unref(SelectItem), {
                                    key: type,
                                    value: type
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(tournamentPrizeTypeEnumLabels)[type]), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "amount" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode("Valor")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Input), {
                              type: "number",
                              modelValue: newPrize.value.amount,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((newPrize.value.amount) = $event)),
                              placeholder: "Valor do prêmio"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "position" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                            _createTextVNode("Posição")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Input), {
                              type: "number",
                              modelValue: newPrize.value.position,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((newPrize.value.position) = $event)),
                              placeholder: "Posição do vencedor"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "description" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormLabel), null, {
                          default: _withCtx(() => _cache[21] || (_cache[21] = [
                            _createTextVNode("Descrição")
                          ])),
                          _: 1
                        }),
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Textarea), {
                              modelValue: newPrize.value.description,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((newPrize.value.description) = $event)),
                              placeholder: "Descrição do prêmio"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_unref(DialogFooter), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Button), {
                    disabled: !newPrize.value.type || !newPrize.value.position || (!newPrize.value.amount && newPrize.value.type === _unref(TournamentPrizeTypeEnum).PIX),
                    type: "submit"
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode("Adicionar Prêmio")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ], 32)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["open"]),
    _createVNode(Toaster)
  ], 64))
}
}

})