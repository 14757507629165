import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, TransitionGroup as _TransitionGroup, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gradient-to-br text-white" }
const _hoisted_2 = {
  key: 0,
  class: "container mx-auto px-4 py-8"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "p-6" }
const _hoisted_5 = { class: "flex justify-between items-start mb-4" }
const _hoisted_6 = { class: "text-xl font-semibold" }
const _hoisted_7 = { class: "text-gray-400 mb-4" }
const _hoisted_8 = { class: "flex items-center justify-between text-sm text-gray-400" }
const _hoisted_9 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "bg-zinc-700 px-6 py-4" }
const _hoisted_12 = { class: "flex items-center justify-between text-sm" }
const _hoisted_13 = { class: "flex items-center" }

import { ref, computed, onMounted } from 'vue'
import { format } from 'date-fns'
import {
  Search as SearchIcon,
  Filter as FilterIcon,
  ChevronDown as ChevronDownIcon,
  Trophy as TrophyIcon,
  Users as UsersIcon,
  Calendar as CalendarIcon,
  ArrowRight as ArrowRightIcon,
  ChartLine as ChartLineIcon
} from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios';
import { Tournament } from '@/interfaces/tournaments/Tournament';
import { Pagination } from '@/interfaces/Pagination';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { TournamentStatusEnum, tournamentStatusLabels } from '@/enums/tournaments/TournamentStatusEnum';
import { TournamentPrizeTypeEnum } from '@/enums/tournaments/prizes/TournamentPrizeTypeEnum';


export default /*@__PURE__*/_defineComponent({
  __name: 'TournamentsIndexView',
  setup(__props) {

const selectedTournament = ref<Tournament>()
const axios = useAxios()
const tournaments = ref<Pagination<Tournament>>()

const formatDate = (date: Date) => {
  return format(date, "MMM d, yyyy")
}

const openTournamentDetails = (tournament: Tournament) => {
  selectedTournament.value = tournament
}

function getTournaments(page = 1) {
  axios.get('/tournaments', { params: { page } })
    .then((res) => {
      tournaments.value = res.data
    })
}

onMounted(() => {
  getTournaments()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (tournaments.value)
      ? (_openBlock(), _createElementBlock("main", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col md:flex-row justify-between items-center mb-8" }, [
            _createElementVNode("h2", { class: "text-3xl font-bold mb-4 md:mb-0 bg-clip-text" }, "Torneios disponíveis")
          ], -1)),
          _createVNode(_TransitionGroup, {
            name: "tournament-list",
            tag: "div",
            class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tournaments.value.data, (tournament) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: tournament.id,
                  class: "bg-card rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-emerald-500/20 hover:scale-105 cursor-pointer",
                  onClick: ($event: any) => (openTournamentDetails(tournament))
                }, [
                  _createVNode(_component_RouterLink, {
                    to: { name: 'tournaments.show', params: { tournamentId: tournament.id } }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("h3", _hoisted_6, _toDisplayString(tournament.name), 1),
                          _createElementVNode("span", {
                            class: _normalizeClass([{
                    'bg-emerald-500': tournament.status === _unref(TournamentStatusEnum).FINISHED,
                    'bg-blue-500': tournament.status === _unref(TournamentStatusEnum).OPEN,
                    'bg-red-500': tournament.status === _unref(TournamentStatusEnum).CLOSED
                  }, "px-2 py-1 rounded-full text-xs font-semibold"])
                          }, _toDisplayString(_unref(tournamentStatusLabels)[tournament.status]), 3)
                        ]),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(tournament.description), 1),
                        _createElementVNode("div", _hoisted_8, [
                          (tournament.prizes.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_unref(TrophyIcon), { class: "mr-2 h-4 w-4 text-yellow-500" }),
                                (tournament.prizes[0].type === _unref(TournamentPrizeTypeEnum).PIX)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode(_toDisplayString(tournament.prizes[0].amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' })), 1)
                                    ], 64))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(tournament.prizes[0].description), 1)
                                    ], 64))
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_unref(UsersIcon), { class: "mr-2 h-4 w-4 text-blue-500" }),
                            _createTextVNode(" " + _toDisplayString(tournament.participantCount) + " participantes ", 1)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_unref(CalendarIcon), { class: "mr-2 h-4 w-4 text-emerald-500" }),
                            _createTextVNode(" " + _toDisplayString(formatDate(tournament.startAt)) + " - " + _toDisplayString(formatDate(tournament.endAt)), 1)
                          ]),
                          _createVNode(_unref(ArrowRightIcon), { class: "h-4 w-4 text-emerald-500" })
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ], 8, _hoisted_3))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
  ]))
}
}

})