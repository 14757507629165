<template>
  <Card class="w-full">
    <CardHeader>
      <CardTitle>Performance</CardTitle>
    </CardHeader>
    <CardContent>
      <div v-if="props.performance && props.performance.length > 0" class="w-full h-64">
        <!-- <LineChart :chart-data="chartData" :options="chartOptions" /> -->
      </div>
      <p v-else>No performance data available.</p>



      <!-- Trading Stats -->
      <div class="mb-6">
        <h3 class="font-semibold mb-2">Trading Stats</h3>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <p class="text-sm text-gray-500">Win Rate</p>
            <p class="font-semibold">{{ user?.tradingStats?.winRate ?? 0 }}%</p>
          </div>
          <div>
            <p class="text-sm text-gray-500">Total Trades</p>
            <p class="font-semibold">{{ user?.tradingStats?.totalTrades ?? 0 }}</p>
          </div>
          <div>
            <p class="text-sm text-gray-500">Avg. Profit</p>
            <p class="font-semibold">{{ user?.tradingStats?.avgProfit ?? 0 }}%</p>
          </div>
          <div>
            <p class="text-sm text-gray-500">Avg. Loss</p>
            <p class="font-semibold">{{ user?.tradingStats?.avgLoss ?? 0 }}%</p>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
</template>

<script setup>
import { computed } from 'vue'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { LineChart } from '@/components/ui/chart-line';
// import { Chart, registerables } from 'chart.js'

// Chart.register(...registerables)

const props = defineProps({
  performance: {
    type: Array,
    default: () => []
  }
})

const chartData = computed(() => ({
  labels: props.performance && Array.isArray(props.performance) ? props.performance.map(p => p.date) : [],
  datasets: [
    {
      label: 'Performance',
      data: props.performance && Array.isArray(props.performance) ? props.performance.map(p => p.value) : [],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }
  ]
}))

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true
    }
  }
}
</script>