import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PanelLayout from '@/layouts/PanelLayout.vue'
import IndexCoursesView from '@/views/courses/IndexCoursesView.vue'
import CreateCourseView from '@/views/courses/CreateCourseView.vue'
import MyCoursesView from '@/views/courses/myCourses/MyCoursesView.vue'
import EditCourseView from '@/views/courses/editCourse/EditCourseView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import ShowLessonView from '@/views/courses/lessons/showLesson/ShowLessonView.vue'
import PasswordRecoveryView from '@/views/auth/PasswordRecoveryView.vue'
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue'
import NotFoundView from '@/views/notFound/NotFoundView.vue'
import ProfileView from '@/views/profile/ProfileView.vue'
import FeedView from '@/views/feed/FeedView.vue'
import RankingView from '@/views/ranking/RankingView.vue'
import CommunitiesLayout from '@/layouts/CommunitiesLayout.vue'
import AffiliateView from '@/views/affiliates/AffiliatesView.vue'
import ChatView from '@/views/chat/ChatView.vue'
import TradersView from '@/views/traders/TradersView.vue'
import ShowCommunityTopicView from '@/views/communities/showCommunityTopic/ShowCommunityTopicView.vue'
import ShowCommunityView from '@/views/communities/showCommunity/ShowCommunityView.vue'
import ShowProfileTopicView from '@/views/profile/showProfileTopic/ShowProfileTopicView.vue'
import IndexCourseUsersView from '@/views/courses/users/IndexCourseUsersView.vue'
import CreateCourseUserView from '@/views/courses/users/CreateCourseUserView.vue'
import CreateCommunityView from '@/views/communities/create/CreateCommunityView.vue'
import EditCommunityView from '@/views/communities/edit/EditCommunityView.vue'
import CreateTournamentView from '@/views/tournaments/create/CreateTournamentView.vue'
import EditTournamentView from '@/views/tournaments/edit/EditTournamentView.vue'
import ManageTournamentsView from '@/views/tournaments/manage/ManageTournamentsView.vue'
import TournamentsIndexView from '@/views/tournaments/index/TournamentsIndexView.vue'
import ShowTournamentView from '@/views/tournaments/show/ShowTournamentView.vue'
import TermsOfUseView from '@/views/termsOfUse/TermsOfUseView.vue'
import NotificationsView from '@/views/notifications/NotificationsView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/password-recovery',
    name: 'auth.password-recovery',
    component: PasswordRecoveryView
  },
  {
    path: '/reset-password/:token',
    name: 'auth.reset-password',
    props: true,
    component: ResetPasswordView
  },
  {
    path: '/',
    component: PanelLayout,
    redirect: '/courses/my',
    children: [
      // {
      //   path: '/',
      //   name: 'home',
      //   component: HomeView
      // },
      {
        path: '/courses',
        name: 'courses.index',
        component: IndexCoursesView
      },
      {
        path: '/courses/create',
        name: 'courses.create',
        component: CreateCourseView
      },
      {
        path: '/',
        name: 'courses.my',
        component: MyCoursesView
      },
      {
        path: '/courses/:courseId/modules/:moduleId/lessons/:lessonId',
        props: true,
        name: 'lessons.show',
        component: ShowLessonView
      },
      {
        path: '/courses/:courseId',
        name: 'courses.edit',
        props: true,
        component: EditCourseView
      },
      {
        path: '/courses/:courseId/users',
        name: 'courses.users.index',
        props: true,
        component: IndexCourseUsersView
      },
      {
        path: '/courses/:courseId/users',
        name: 'courses.users.create',
        props: true,
        component: CreateCourseUserView
      },
      {
        path: '/profile/:profileId',
        name: 'profile',
        props: true,
        component: ProfileView
      },
      {
        path: '/profile/:profileId/topics/:profileTopicId',
        name: 'profile.topics.show',
        props: true,
        component: ShowProfileTopicView
      },
      {
        path: '/affiliates',
        name: 'affiliates',
        component: AffiliateView
      },
      {
        path: '/traders',
        name: 'traders',
        component: TradersView
      },
      {
        path: '/chat',
        name: 'chat',
        component: ChatView
      },
      {
        path: '',
        component: CommunitiesLayout,
        children: [
          {
            path: '/feed',
            name: 'feed',
            component: FeedView
          },
          {
            path: '/courses/communities',
            name: 'courses.communities.create',
            component: CreateCommunityView
          },
          {
            path: '/courses/communities/:communityId',
            props: true,
            name: 'courses.communities.edit',
            component: EditCommunityView
          },
          {
            path: '/courses/:courseId/communities/:communityId',
            name: 'courses.communities.show',
            props: true,
            component: ShowCommunityView
          },
          {
            path: '/courses/communities/topics/:communityTopicId',
            name: 'courses.communities.topics.show',
            props: true,
            component: ShowCommunityTopicView
          },
        ]
      },
      {
        path: '/ranking',
        name: 'ranking',
        component: RankingView
      },
      {
        path: '/tournaments',
        name: 'tournaments',
        component: TournamentsIndexView
      },
      {
        path: '/manage-tournaments',
        name: 'tournaments.manage',
        component: ManageTournamentsView
      },
      {
        path: '/tournaments/create',
        name: 'tournaments.create',
        component: CreateTournamentView
      },
      {
        path: '/edit-tournament/:tournamentId',
        name: 'tournaments.edit',
        props: true,
        component: EditTournamentView
      },
      {
        path: '/tournaments/:tournamentId',
        name: 'tournaments.show',
        props: true,
        component: ShowTournamentView
      },
      {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: TermsOfUseView
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsView
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
